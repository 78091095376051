import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { useParams, withRouter } from "react-router-dom";
import Select from 'react-select'
import SelectSearch from "react-select-search";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeAppointmentRequest, getActesConsultationAction, startSecondLoader } from "../../../store/actions/ConsultationActions";
import { startLoader, listProviderDoctorsAction, getProviderDataAction } from "../../../store/actions/ProviderActions";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { Button, Spinner } from "react-bootstrap";


export const AppointmentRequest = (props) => {
    const dispatch = useDispatch();
    const { history, currentUser, providers, showLoading, provider, showSecondLoading } = props;
    const [doctor, setDoctor] = useState("");
    const [choosenProvider, setChoosenProvider] = useState({});
    const [date, setDate] = useState(new Date());
    const [motif, setMotif] = useState("");
    const [acteId, setActeId] = useState("");
    const [acteCode, setActeCode] = useState("");
    const [acteName, setActeName] = useState("");
    const motives = [{label: "Maternité", value: 'maternity'}, {label: "Accident", value: "accident"}, {label: "Maladie naturelle", value: "sickness"}];
    

     const requestAppointment = (e) => {
        console.log("submission is called");
        e.preventDefault();
        dispatch(startSecondLoader());
        let data = {
            providerId: choosenProvider.value,
            providerName: choosenProvider.label,
            patientId: currentUser.patientId,
            patientName: currentUser.username,
            dateRdv: date,
            doctorId: doctor.value,
            doctorName: doctor.label,
            motifRdv: motif.label,
            acteId,
            acteCode,
            acteName,
        }

        dispatch(makeAppointmentRequest(data, history));
     }

     const chooseProvider = (val) => {
        setChoosenProvider(val);
        dispatch(getProviderDataAction(val.value, history))
     }

     useEffect(() => {
        console.log("gathering infos");
        console.log(showSecondLoading);
        dispatch(startLoader());
        dispatch(getActesConsultationAction());
        dispatch(listProviderDoctorsAction());
    }, []);

    return (
        <Fragment>
            { showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }
            { !showLoading && 
            <div className="row">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                        <h4 className="card-title">Demander un rendez-vous</h4>
                        </div>
                        <div className="card-body">
                        <form
                            onSubmit={(e) => requestAppointment(e)}
                        >
                                <section>
                                    <div className="row">
                                        <div className="col-lg-4 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Clinique / Hôpital</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={providers.map(prov => {
                                                        return {label: prov.providerName, value: prov.id, users: prov.user}
                                                    })}
                                                    placeholder="Choisir une clinique"
                                                    disabled={showLoading}
                                                    search
                                                    isRequired
                                                    onChange={(val) => {
                                                        chooseProvider(val);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Médecin</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={choosenProvider.users?.map(provUser => {
                                                        return {label: provUser.username, value: provUser.id}
                                                    })}
                                                    name="Type"
                                                    placeholder="Choisir un type"
                                                    disabled={showLoading}
                                                    search
                                                    isRequired
                                                    onChange={setDoctor}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Type de consultation</strong>
                                                </label>
                                                
                                                <Select
                                                    className="select-search"
                                                    options={props.actesConsultation.map(act => {
                                                        return {label: act.nameFr, value: act.id, code: act.code}
                                                    })}
                                                    name="Type"
                                                    placeholder="Choisir un type"
                                                    disabled={props.showSecondLoading}
                                                    search
                                                    isRequired
                                                    onChange={(val) => {
                                                        setActeId(val.value);
                                                        setActeName(val.label);
                                                        setActeCode(val.code);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-lg-6 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Date et heure</strong>
                                                </label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DateTimePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        format="dd/MM/yyyy hh:mm"
                                                        value={date}
                                                        disablePast
                                                        disabled={showLoading}
                                                        required
                                                        onChange={setDate}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Motif du rendez-vous</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    options={motives}
                                                    name="Type"
                                                    placeholder="Choisir un type"
                                                    disabled={showLoading}
                                                    search
                                                    isRequired
                                                    onChange={setMotif}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                
                                <div className='row justify-content-center mt-4'>
                                    { showSecondLoading && 
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            <span className="visually-hidden">Un instant...</span>
                                        </Button> }
                                    { !showSecondLoading &&
                                        <div className='col-4'>
                                            <input type='submit' className='btn btn-primary btn-block'/>
                                        </div> }
                                </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
            }
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        providers: state.provider.providerDoctors,
        provider: state.provider.provider,
        showLoading: state.provider.showLoading,
        showSecondLoading: state.consultation.showSecondLoading,
        actesConsultation: state.consultation.actesConsultation,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(AppointmentRequest));