import {
    GET_BENEF_CONFIRMED_ACTION,
    GET_BENEF_FAILED_ACTION,
    LOADING_TOGGLE_ACTION,
    GET_ASSUR_BENEF_CONFIRMED_ACTION,
    GET_ASSUR_BENEF_FAILED_ACTION,
    GET_SOUSCRPTS_CONFIRMED_ACTION,
    GET_SOUSCRIPTS_FAILED_ACTION,
    GET_GARANTIES_CONFIRMED_ACTION,
    GET_GARANTIES_FAILED_ACTION,
    GET_ACTES_CONFIRMED_ACTION,
    GET_ACTES_FAILED_ACTION,
    SEARCH_CONTRAT_CONFIRMED_ACTION,
    SEARCH_CONTRAT_FAILED_ACTION,
    GET_BENEF_DATA_CONFIRMED_ACTION,
    GET_BENEF_DATA_FAILED_ACTION,
    GET_ALL_BENEF_CONFIRMED_ACTION,
    GET_ALL_BENEF_FAILED_ACTION,
    GET_ASS_PRINC_CONFIRMED_ACTION,
    GET_ASS_PRINC_FAILED_ACTION,
    GET_CONTRATS_CONFIRMED_ACTION,
    GET_CONTRATS_FAILED_ACTION,
    GET_ALL_SOUSCRPTS_CONFIRMED_ACTION,
    GET_ALL_SOUSCRIPTS_FAILED_ACTION,
    GET_ALL_ASS_PRINC_CONFIRMED_ACTION,
    GET_ALL_ASS_PRINC_FAILED_ACTION,
    MAP_ACTE_PROD_GARAN_CONFIRMED_ACTION,
    MAP_ACTE_PROD_GARAN__FAILED_ACTION,
    GET_BENEF_HISTO_CONFIRMED_ACTION,
    GET_BENEF_HISTO_FAILED_ACTION,
    GET_CONTRATS_HISTO_CONFIRMED_ACTION,
    GET_CONTRATS_HISTO_FAILED_ACTION,
    GET_SOUSCRPTS_HISTO_CONFIRMED_ACTION,
    GET_SOUSCRIPTS_HISTO_FAILED_ACTION,
    GET_COLLEGE_PER_CONTRACT_CONFIRMED_ACTION,
    GET_FAMILLE_PER_COLLEGE_CONFIRMED_ACTION,
    GET_CONTRACT_PER_CLIENT_CONFIRMED_ACTION,
    REQUEST_AVENANT_CONFIRMED_ACTION,
    GET_REQUEST_AVENANT_CONFIRMED_ACTION
} from '../actions/ContratActions';

const initialState = {
    beneficiaires: [],
    assureBenefs: [],
    souscripteurs: [],
    beneficiaire: {},
    garanties: [],
    actes: [],
    searchedContrats: [],
    allSouscripteurs: [],
    allContrats: [],
    allAssuresPrincipaux: [],
    allBeneficiaires: [],
    assuresPrincipaux: [],
    contratsHisto: [],
    beneficiairesHisto: [],
    souscripteursHisto: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    contratPerClient: [],
    collegePerContrat: [],
    famillePerCollege: [],
    incorporationRequests: [],
    retraitRequests: [],
    suspensionRequests: [],
    annulationRequests: [],
    renouvellementRequests: [],
    changementCollegeRequests: [],
};

export const ContratReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BENEF_CONFIRMED_ACTION:
            return {
                ...state,
                beneficiaires: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_BENEF_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_ASSUR_BENEF_CONFIRMED_ACTION:
            return {
                ...state,
                assureBenefs: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_ASSUR_BENEF_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_SOUSCRPTS_CONFIRMED_ACTION:
            return {
                ...state,
                souscripteurs: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_SOUSCRIPTS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_GARANTIES_CONFIRMED_ACTION:
            return {
                ...state,
                garanties: action.payload,
                errorMessage: '',
                successMessage: 'Garanties listing Successfully Completed',
                showLoading: false,
            };
        case GET_GARANTIES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_ACTES_CONFIRMED_ACTION:
            return {
                ...state,
                actes: action.payload,
                errorMessage: '',
                successMessage: 'Actes listing Successfully Completed',
                showLoading: false,
            };
        case GET_ACTES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case SEARCH_CONTRAT_CONFIRMED_ACTION:
            return {
                ...state,
                searchedContrats: action.payload,
                errorMessage: '',
                successMessage: 'Contrats search Successfully Completed',
                showLoading: false,
            };
        case SEARCH_CONTRAT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_BENEF_DATA_CONFIRMED_ACTION:
            return {
                ...state,
                beneficiaire: action.payload,
                errorMessage: '',
                successMessage: 'Notifications listing Successfully Completed',
                showLoading: false,
            };
        case GET_BENEF_DATA_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_ALL_BENEF_CONFIRMED_ACTION:
            return {
                ...state,
                allBeneficiaires: action.payload,
                errorMessage: '',
                successMessage: 'Benefs listing Successfully Completed',
                showLoading: false,
            };
        case GET_ALL_BENEF_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_ALL_SOUSCRPTS_CONFIRMED_ACTION:
            return {
                ...state,
                allSouscripteurs: action.payload,
                errorMessage: '',
                successMessage: 'Souscript listing Successfully Completed',
                showLoading: false,
            };
        case GET_ALL_SOUSCRIPTS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_ALL_ASS_PRINC_CONFIRMED_ACTION:
            return {
                ...state,
                allAssuresPrincipaux: action.payload,
                errorMessage: '',
                successMessage: 'AssPrinc listing Successfully Completed',
                showLoading: false,
            };
        case GET_ALL_ASS_PRINC_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_ASS_PRINC_CONFIRMED_ACTION:
            return {
                ...state,
                assuresPrincipaux: action.payload,
                errorMessage: '',
                successMessage: 'assure principaux listing Successfully Completed',
                showLoading: false,
            };
        case GET_ASS_PRINC_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_CONTRATS_CONFIRMED_ACTION:
            return {
                ...state,
                allContrats: action.payload,
                errorMessage: '',
                successMessage: 'Contrats listing Successfully Completed',
                showLoading: false,
            };
        case GET_CONTRATS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case MAP_ACTE_PROD_GARAN_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                actes: state.actes.map(act => {
                    if(act.id === action.payload.id) {
                        act = action.payload;
                    }
                    return act;
                }),
                successMessage: 'mapping Successfully Completed',
                showLoading: false,
            };
        case MAP_ACTE_PROD_GARAN__FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_BENEF_HISTO_CONFIRMED_ACTION:
            return {
                ...state,
                beneficiairesHisto: action.payload,
                errorMessage: '',
                successMessage: 'Benef histo listing Successfully Completed',
                showLoading: false,
            };
        case GET_BENEF_HISTO_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_CONTRATS_HISTO_CONFIRMED_ACTION:
            return {
                ...state,
                contratsHisto: action.payload,
                errorMessage: '',
                successMessage: 'Contrat histo listing Successfully Completed',
                showLoading: false,
            };
        case GET_CONTRATS_HISTO_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_SOUSCRPTS_HISTO_CONFIRMED_ACTION:
            return {
                ...state,
                souscripteursHisto: action.payload,
                errorMessage: '',
                successMessage: 'Souscripteurs histo listing Successfully Completed',
                showLoading: false,
            };
        case GET_SOUSCRIPTS_HISTO_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_CONTRACT_PER_CLIENT_CONFIRMED_ACTION:
            return {
                ...state,
                contratPerClient: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_FAMILLE_PER_COLLEGE_CONFIRMED_ACTION:
            return {
                ...state,
                famillePerCollege: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_COLLEGE_PER_CONTRACT_CONFIRMED_ACTION:
            return {
                ...state,
                collegePerContrat: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case REQUEST_AVENANT_CONFIRMED_ACTION:
            return {
                ...state,
                successMessage: 'Request saved successfully',
                showLoading: false,
            };
        case GET_REQUEST_AVENANT_CONFIRMED_ACTION:
            return {
                ...state,
                incorporationRequests: action.payload.typeData === 'incorporation' ? action.payload.data : [],
                retraitRequests: action.payload.typeData === 'retrait' ? action.payload.data : [],
                suspensionRequests: action.payload.typeData === 'suspension' ? action.payload.data : [],
                annulationRequests: action.payload.typeData === 'annulation' ? action.payload.data : [],
                renouvellementRequests: action.payload.typeData === 'renouvellement' ? action.payload.data : [],
                changementCollegeRequests: action.payload.typeData === 'changementCollege' ? action.payload.data : [],
                successMessage: '',
                showLoading: false,
            };
        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                showLoading: action.payload,
            };
        default:
          return state;
    };
};