import React, { useState } from 'react'
import { connect } from 'react-redux'
/// React router dom
import {Switch, Route, Redirect } from 'react-router-dom'
/// Css
import './index.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
//import Home from "./components/Dashboard/Home";
import PatientDetails from "./components/Patient/PatientDetails";
import DossierMedialPane from './components/DossierMedical/DossierMedialPane';
import AppointmentList from './components/Appointments/AppointmentsList/AppointmentList';
import AppointmentRequest from './components/Appointments/AppointmentRequest';
import ProviderMap from './components/Providers/ProviderMap/ProviderMap';
import RemboursementRequest from './components/Remboursement/RemboursementForm/RemboursementRequest';
import RemboursementDetails from './components/Remboursement/RemboursementView/RemboursementDetails';
import RemboursementList from './components/Remboursement/RemboursementView/RemboursementList';
import FacturationDetails from './components/Reports/Factures/FacturationDetails';
import FacturationList from './components/Reports/Factures/FacturationList';
import EtatsPanel from './components/Rapports/EtatsPanel';
import ListAssures from './components/GestionPolice/ListAssures';
import Dashboard from './components/Dashboard/Dashboard';
/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import LockScreen from './pages/LockScreen'
import Profile from './pages/Profile'
import Error403 from './pages/Error403'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import { currentUser } from '../store/selectors/CurrentUserSelector'
import Help from './pages/Help'


const Markup = (props) => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: "", component: currentUser.patientId ? PatientDetails : Dashboard },
    { url: "dossier-patient", component: PatientDetails },
    { url: "dashboard", component: Dashboard },
	  //{ url: "my-profile", component: PatientDetails },
    /// Prestataires
    { url: 'provider-map', component: ProviderMap },
    /// pages
    { url: 'page-register', component: Registration },
    { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },
    { url: 'profile', component: Profile },
    { url: 'help-center', component: Help },
    //Dossier Médical
    { url: 'dossier-medical/:id', component: DossierMedialPane },
    //Rendez-vous
    { url: 'appointments-list', component: AppointmentList },
    { url: 'appointment-new', component: AppointmentRequest },
    //Remboursement
    { url: 'demande-remboursement', component: RemboursementRequest },
    { url: 'demande-remboursement-update/:id', component: RemboursementRequest },
    { url: 'remboursement-list', component: RemboursementList },
    { url: 'remboursement-details/:id', component: RemboursementDetails },
    //Facturation
    { url: 'facturation', component: FacturationList },
    { url: 'facturation-details/:id', component: FacturationDetails },
    // Rapport
    { url: 'rapports/mes-consommations', component: EtatsPanel},
    //Gestion police
    { url: 'assures/liste', component: ListAssures},
    //Error pages
    { url: 'access-denied', component: Error403 },


  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    //element={ props.currentUser && props.currentUser.isFirstLogin ? <Redirect replace to="/profile#profile-settings" /> : <data.component /> }
                    //component={data.component}
                    >
                      { props.currentUser && props.currentUser.isFirstLogin && !data.url.includes('profile') && !data.url.includes('help') ? 
                        <Redirect to="/profile#profile-settings" /> : 
                        <data.component /> 
                      }
                    </Route>
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}
const mapStateToProps = (state) => {
    return {
       currentUser: currentUser(state)
    };
};
export default connect(mapStateToProps)(Markup);
