import React from "react";
///import language
import Lang from "./nav/lang";
const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © Activa 2022
          <Lang></Lang>
        </p>
        
      </div>
     
    </div>
  );
};

export default Footer;
