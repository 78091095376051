import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { requestAvenantAction, getCollegePerContractAction, loadingToggleAction } from "../../../store/actions/ContratActions";
import { Button, Modal, Spinner } from "react-bootstrap";
import Select from 'react-select';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from "react-i18next";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const IncorporationModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sexes = [
        {label: "Masculin", value: "M"},
        {label: "Féminin", value: "F"}
    ];
    const { collegePerContrat, polices, currentUser, showLoading } = props;
    const [numPolice, setNumPolice] = useState('');
    const [collegeId, setCollegeId] = useState('');
    const [dateEffet, setDateEffet] = useState(new Date());
    const [assures, setAssures] = useState([{
        noms: "",
        prenoms: "",
        dateNaiss: "",
        sexe: "",
        telephone: "",
        email: "",
        isAssurePrinc: true
    }]);

    

    const removeLine = (e, index) => {
        e.preventDefault();
        setAssures(assures.filter((elt, i) => i !== index).map((val, j) => {
            if(j === 0){
                val.isAssurePrinc = true;
            }
            return val;
        }));
    }

    const addLine = (e) => {
        e.preventDefault();
        setAssures([...assures, {
            noms: "",
            prenoms: "",
            dateNaiss: "",
            sexe: "",
            telephone: "",
            email: "",
            isAssurePrinc: false
        }]);
    }


    
    const onSave = (e) => {
        e.preventDefault();

        let data = {
            codeAssu: currentUser.clientId,
            numPolice,
            collegeId,
            dateEffet,
            assures
        }
        console.log(data);
        dispatch(loadingToggleAction(true));
        dispatch(requestAvenantAction(data, 'incorporation'));
    }

    return (
        <form onSubmit={e => onSave(e)}>
            <div className='row my-2 align-items-center'>
                <div className={showLoading ? "col-3" : "col-4"}>
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Police</strong>
                            <span className='text-danger ml-1'>*</span>
                        </label>
                        <Select
                            className="select-search"
                            value={{label: numPolice, value: numPolice}}
                            options={polices?.map((opt)=>{return {label:opt, value:opt}})}
                            placeholder="Choisir une police"
                            search
                            onChange={(val) => {
                                setNumPolice(val.value);
                                dispatch(loadingToggleAction(true));
                                dispatch(getCollegePerContractAction(val.value));
                            }}
                            
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </div>
                </div>
                
                {showLoading && <div className="col-1">
                    <ProgressSpinner style={{width: '25px', height: '25'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>}
                <div className="col-4">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Collège</strong>
                            <span className='text-danger ml-1'>*</span>
                        </label>
                        <Select
                            className="select-search"
                            value={collegePerContrat?.filter(elt => elt.numeroCollege === collegeId).map((opt)=>{return {label:opt.libelleGroupe,value:opt.numeroCollege}})}
                            options={[{value: '', label: "Choisir"}, ...collegePerContrat?.map((opt)=>{return {label:opt.libelleGroupe,value:opt.numeroCollege}})]}
                            placeholder="Choisir un collège"
                            search
                            
                            onChange={(val) => {
                                setCollegeId(val.value);
                            }}
                            style={{
                                lineHeight: '40px',
                                color: '#7e7e7e',
                                paddingLeft: ' 15px',
                            }}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label className='mb-1 '>
                            <strong>Date effet</strong>
                            <span className='text-danger ml-1'>*</span>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                autoOk
                                label=""
                                clearable
                                format="dd/MM/yyyy"
                                disablePast
                                value={dateEffet}
                                onChange={setDateEffet}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div>
            <h4 className="text-primary">Assurés à incorporer</h4>
            <div className="border-top border-primary mt-2 mb-4"></div>
            {assures.map((assure, i) => {
                return <>
                    {i===0 && <h6 className="text-secondary">** Assuré principal **</h6>}
                    {i>0 && <h6 className="text-secondary">** Bénéficiaire N°{i} **</h6>}
                    <div className='row my-2 align-items-center'>
                        <div className="col-4">
                            <div className="form-group">
                                <label className='mb-1 '>
                                    <strong>Noms</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                
                                <input type='text' className='form-control'
                                    value={assure.noms}
                                    required
                                    onChange={(e) => {
                                        setAssures(assures.map((elt, j) => {
                                            elt.noms = (i === j) ? e.target.value : elt.noms;
                                            return elt;
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className='mb-1 '>
                                    <strong>Prénoms</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input type='text' className='form-control'
                                    value={assure.prenoms}
                                    required
                                    onChange={(e) => {
                                        setAssures(assures.map((elt, j) => {
                                            elt.prenoms = (i === j) ? e.target.value : elt.prenoms;
                                            return elt;
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className='mb-1 '>
                                    <strong>Date de naissance</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        label=""
                                        clearable
                                        format="dd/MM/yyyy"
                                        disableFuture
                                        value={assure.dateNaiss}
                                        onChange={val => {
                                            setAssures(assures.map((elt, j) => {
                                                elt.dateNaiss = (i === j) ? val : elt.dateNaiss;
                                                return elt;
                                            }))
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className="col-2">
                            <div className="form-group">
                                <label className='mb-1 '>
                                    <strong>Sexe</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <Select
                                    className="select-search"
                                    value={sexes?.filter(elt => elt.value === assure.sexe)}
                                    options={sexes}
                                    placeholder="Choisir"
                                    search
                                    onChange={(val) => {
                                        setAssures(assures.map((elt, j) => {
                                            elt.sexe = (i === j) ? val.value : elt.sexe;
                                            return elt;
                                        }))
                                    }}
                                    style={{
                                        lineHeight: '40px',
                                        color: '#7e7e7e',
                                        paddingLeft: ' 15px',
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label className='mb-1 '>
                                    <strong>Téléphone</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input type='tel' className='form-control'
                                    value={assure.telephone}
                                    required
                                    onChange={(e) => {
                                        setAssures(assures.map((elt, j) => {
                                            elt.telephone = (i === j) ? e.target.value : elt.telephone;
                                            return elt;
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                        
                        <div className="col-4">
                            <div className="form-group">
                                <label className='mb-1 '>
                                    <strong>Email</strong>
                                    <span className='text-danger ml-1'>*</span>
                                </label>
                                <input type='email' className='form-control'
                                    value={assure.email}
                                    required
                                    onChange={(e) => {
                                        setAssures(assures.map((elt, j) => {
                                            elt.email = (i === j) ? e.target.value : elt.email;
                                            return elt;
                                        }))
                                    }}
                                />
                            </div>
                        </div>

                        
                        {(assures.length > 1)  && <div className='col-1' style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                        }}>
                            <button className='modal-btn btn btn-outline-danger' onClick={e => removeLine(e, i)}>
                                <i className='fa fa-trash'></i>
                            </button>
                        </div>}

                        
                        {(i === assures.length - 1)  && <div className='col-1' style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                        }}>
                            <button className='modal-btn btn btn-outline-success' onClick={e => addLine(e)}>
                                <i className='fa fa-plus'></i>
                            </button>
                        </div>}
                    </div>
                    <div className="border-top border-secondary my-2"></div>
                </>
            })}
            <div className='row justify-content-center mt-4'>
                { showLoading && 
                    <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />{' '}
                        <span className="visually-hidden">Un instant...</span>
                    </Button> }
                { !showLoading &&
                    <div className='col-4'>
                        <input type='submit' className='btn btn-primary btn-block'/>
                    </div> }
            </div>
        </form>  
    )
};

const mapStateToProps = (state) => {
    return {
        collegePerContrat: state.contrat.collegePerContrat,
        showLoading: state.contrat.showLoading
    };
};

export default connect(mapStateToProps)(IncorporationModal);