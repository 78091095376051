const translations = {

    en: {
        accord: {
          list: {
              error: "Error retrieving agreement requests: "
          },
          validate: {
            error: "Error validating the agreement: ",
            success: "Agreement validated successfully"
          },
          validate: {
            error: "Error rejecting the agreement: ",
            success: "Agreement rejected successfully"
          },
          sendMessage: {
            error: "Error sending message: ",
            success: "Message sent successfully"
          },
          getAgreement: {
            error: "Error retrieving the agreement request: "
          }
        },
        actSpec: {
            getBon: {
                error: "Error retrieving provider: "
            },
            markAct: {
                error: "Error recording the action taken: ",
                success: "Act marked completed successfully recorded"
            }
        },
        auth: {
          error: "Authentication Failure: ",
          welcome: "Welcome ",
          updatePass: {
            success: "Your password has been successfully updated, please log in again using your new password",
            error: "Failed to update password: "
          }
        },
        consultation: {
            getConsult: {
                error: "Error retrieving consultations: "
            },
            getConsultData: {
                error: "Error retrieving the consultation: "
            },
            makeConsult: {
                error: "Error during consultation request: "
            },
            searchDrug: {
                error: "Error retrieving drugs: "
            },
            searchDisease: {
                error: "Error retrieving conditions: "
            },
            getSuggest: {
                error: "Error retrieving suggestions: "
            },
            updateConsult: {
                error: "Error updating the consultation: "
            },
            getClientConsult: {
                error: "Error retrieving consultations for this patient: "
            },
            medicalFile: {
                error_one: "Error retrieving medical record: ",
                error_two: "Error retrieving medical records: "
            },
            getPatientParam: {
                error: "Error retrieving parameter histories for this patient: "
            },
            makeAppointment: {
                error: "Error requesting an appointment: "
            },
            getPatientAppointment: {
                error: "Error retrieving appointments: ",
                error_data: "Error retrieving the appointment: "
            }
        },
        contrat: {
            getBenef: {
                error: "Error retrieving beneficiaries: ",
                by_assu_error: "Error retrieving beneficiaries: "
            },
            getSouscript: {
                error: "Error retrieving subscribers: "
            },
            getGaranties: {
                error: "Error retrieving collateral: "
            },
            getActes: {
                error: "Error retrieving records: "
            },
            contractSearch: {
                error: "Error searching for contract: "
            }
        },
        factures: {
            getFactures: {
                error: "Error retrieving invoices: ",
                error_single: "Error retrieving invoice: "
            },
            getMotifs: {
                error: "Error retrieving exclusion reasons: "
            },
            rejet: {
                save_success: "Exclusion saved",
                save_error: "Error rejecting: ",
                restore_success: "Exclusion canceled",
                restore_error: "Error restoring: "
            },
            rembours: {
                request_success: "Request saved",
                request_error: "Error requesting refund: ",
                gets_error: "Error retrieving refunds: ",
                get_error: "Error retrieving refund: ",
                update_success: "Refund request saved",
                update_error: "Error saving refund request: "
            }
        },
        help: {
            success: "Your message has been sent successfully, support will contact you as soon as possible.",
            error: "Error sending message: "
        },
        labo: {
            get_bon_error: "Error retrieving provider: ",
            delievery_exam_error: "Delivery successfully recorded",
            comment_success: "Comment successfully recorded",
            comment_error: "Error while commenting: ",
            getPatientResult: "Error retrieving test results: "
        },
        patient: {
            identification: {
              success_one: "Patient identified",
              success_two: "Patient data retrieved",
              error_one: "Patient identification failed: ",
              error_two: "Patient identification failed: "
            }
        },
        pharmacie: {
            get_bon_error: "Error retrieving provider: ",
            get_subs_error: "Error retrieving substitution requests: ",
            livraison_success: "Delivery successfully recorded",
            livraison_error: "Error during delivery: ",
            subs_req_success: "Substitution request successfully recorded",
            subs_req_error: "Error during substitution request: ",
            subs_validated_success: "Substitution successfully validated",
            subs_validated_error: "Error during substitution validation: ",
            subs_rejected_success: "Substitution successfully rejected",
            subs_rejected_error: "Error during substitution rejection: "
        },
        provider: {
            get_error: "Error retrieving provider: ",
            add_prov_bank_error: "Error saving provider payment information: ",
            search_act_error: "Error retrieving drugs: ",
            list_providers_error: "Error listing providers: ",
            list_provider_error: "Error listing providers: "
        }  
    },
  
    fr: {
      accord: {
        list: {
            error: "Erreur lors de la récupération des demandes d'accord: "
        },
        validate: {
          error: "Erreur lors de la validation de l'acte: ",
          success: "Accord validé avec succès"
        },
        validate: {
          error: "Erreur lors du rejet de l'acte: ",
          success: "Accord rejeté avec succès"
        },
        sendMessage: {
          error: "Erreur lors de l'envoie du message: ",
          success: "Message envoyé avec succès"
        },
        getAccord: {
          error: "Erreur lors de a récupération de la demande d'accord': "
        }
      },
      actSpec: {
        getBon: {
            error: "Erreur lors de la récupération du provider: "
        },
        markAct: {
            error: "Erreur lors de l'enregistrement de l'acte posé: ",
            success: "Acte marqué éffectué enregistrée avec succès"
        }
      },
      auth: {
        error: "Echec d'authentification: ",
        welcome: "Bienvenue ",
        updatePass: {
          success: "Votre mot de passe a bien été mis à jour, veuilez vous reconnecter en utilisant votre nouveau mot de passe",
          error: "Echec de mise à jour du mot de passe: "
        }
      },
      consultation: {
        getConsult: {
            error: "Erreur lors de la récupération des consultation: "
        },
        getConsultData: {
            error: "Erreur lors de la récupération de la consultation: "
        },
        makeConsult: {
            error: "Erreur lors de la requete de consultation: "
        },
        searchDrug: {
            error: "Erreur lors de la récupération du drugs: "
        },
        searchDisease: {
            error: "Erreur lors de la récupération des affections: "
        },
        getSuggest: {
            error: "Erreur lors de la récupération des suggestions: "
        },
        updateConsult: {
            error: "Erreur lors de la mise à jour de la consultation: "
        },
        getClientConsult: {
            error: "Erreur lors de la récupération des consultations pour ce patient: "
        },
        medicalFile: {
            error_one: "Erreur lors de la récupération du dossier médical: ",
            error_two: "Erreur lors de la récupération du dossier médical: "
        },
        getPatientParam: {
            error: "Erreur lors de la récupération des historiques des paramètre pour ce patient: "
        },
        makeAppointment: {
            error: "Erreur lors de la requête de rendez-vous: "
        },
        getPatientAppointment: {
            error: "Erreur lors de la récupération des rendez-vous: ",
            error_data: "Erreur lors de la récupération du rendez-vous: "
        }
      },
      contrat: {
        getBenef: {
            error: "Erreur lors de la récupération des bénéficiaires: ",
            by_assu_error: "Erreur lors de la récupération des bénéficiaires: "
        },
        getSouscript: {
            error: "Erreur lors de la récupération des souscripteurs: "
        },
        getGaranties: {
            error: "Erreur lors de la récupération des garanties: "
        },
        getActes: {
            error: "Erreur lors de la récupération des actes: "
        },
        contratSearch: {
            error: "Erreur lors de la recherche du contrat: "
        }
      },
      factures: {
        getFactures: {
            error: "Erreur lors de la récupération des factures: ",
            error_single: "Erreur lors de la récupération de la facture: "
        },
        getMotifs: {
            error: "Erreur lors de la récupération des motifs d'exclusions: "
        },
        rejet: {
            save_success: "Exclusion enregistrée",
            save_error: "Erreur lors du rejet: ",
            restore_success: "Exclusion annulée",
            restore_error: "Erreur lors de la restauration: "
        },
        rembours: {
            request_success: "Demande enregistrée",
            request_error: "Erreur lors de la demande de remboursement: ",
            gets_error: "Erreur lors de la récupération des remboursements: ",
            get_error: "Erreur lors de la récupération du remboursement: ",
            update_success: "Demande de remboursement enregistrée",
            update_error: "Erreur lors de l'enregistrement de la demande de remboursement: "
        }
      },
      help: {
        success: "Votre message a bien été envoyé, le support vous contactera dans les plus brefs délais.",
        error: "Erreur lors de l'envoie du message: "
      },
      labo: {
        get_bon_error: "Erreur lors de la récupération du provider: ",
        deliever_exam_error: "Livraison enregistrée avec succès",
        comment_success: "Commentaire enregistrée avec succès",
        comment_error: "Erreur lors du commentaire: ",
        getPatientResult: "Erreur lors de récupération des résultats d'analyse: "
      },
      patient: {
        identification: {
            success_one: "Patient identifié",
            success_two: "Données Patient retrouvées",
            error_one: "Echec d'identification du patient: ",
            error_two: "Echec d'identification du patient: "
        }
      },
      pharmacie: {
        get_bon_error: "Erreur lors de la récupération du provider: ",
        get_subs_error: "Erreur lors de la récupération des demandes de substitution: ",
        livraison_success: "Livraison enregistrée avec succès",
        livraison_error: "Erreur lors de la livraison: ",
        subs_req_success: "Demande de substitution enregistrée avec succès",
        subs_req_error: "Erreur lors de la demande de substitution: ",
        subs_validated_success: "Substitution validée avec succès",
        subs_validated_error: "Erreur lors de la validation substitution: ",
        subs_rejected_success: "Substitution rejetée avec succès",
        subs_rejected_error: "Erreur lors du rejet de la substitution: "
      },
      provider: {
        get_error: "Erreur lors de la récupération du provider: ",
        add_prov_bank_error: "Erreur lors de l'enregistrement des infos de paiement du prestataire: ",
        search_act_error: "Erreur lors de la récupération du drugs: ",
        list_providers_error: "Erreur lors du listing des prestataire: ",
        list_provider_error: "Erreur lors du listing des prestataire: "
      }
    },
  
  };
  
  export default translations;