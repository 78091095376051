import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const FacturationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

const ParamServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND,
  headers: headers 
});

// Règlements
export const getFactures = (assureId) => FacturationServiceRequest.get('/factures/patient/'+assureId);
export const getFacture = (id) => FacturationServiceRequest.get('/factures/'+id);
export const getMotifExclusion = () => ParamServiceRequest.get('/Parameter/exclusions');
export const saveRejet = (id, data) => FacturationServiceRequest.put('/factures/exclusion/'+id, data);
export const restoreRejet = (id) => FacturationServiceRequest.put('/factures/exclusion/'+id+'/restore');

// Remboursements
export const getRemboursements = (id) => FacturationServiceRequest.get('/remboursement/patient/'+id);
export const getRemboursement = (id) => FacturationServiceRequest.get('/facture/'+id+'/patient');
export const requestRemboursement = (data) => FacturationServiceRequest.post('/factures/remboursement-detail', data);
export const updateRemboursement = (id, data) => FacturationServiceRequest.put('/factures/remboursement-request/'+id, data);
