import React from "react";

const AppointmentPagination = (props) => {
    const { totalData, total, currentPage, switchPage, max } = props;
    console.log(total)
    // Active paggination & chage data
    const goToPage = (page) => {
        switchPage(page);
    };

    
    return (
        <div className="d-sm-flex text-center justify-content-between align-items-center">
            <div
                className="dataTables_info"
                id="example5_info"
                role="status"
                aria-live="polite"
            >
                Showing {((currentPage - 1) * max) + 1 <= totalData ? ((currentPage - 1) * max) : totalData} to{" "}
                {(((currentPage - 1) * max) + 1) + max <= totalData ? (((currentPage - 1) * max) + 1) + max : totalData}{" "}
                of {totalData} entries
            </div>
            <div className="dataTables_paginate paging_simple_numbers d-flex  justify-content-center align-items-center pb-3">
                {currentPage > 1 && <button
                    className="paginate_button previous disabled"
                    onClick={() => goToPage(currentPage - 1)}
                >
                    Previous
                </button>}
                <span className="d-flex" style={{maxWidth: "40rem", overflow: "auto"}}>
                    {Array.from(Array(total).keys()).map((number, i) => (
                        <button
                        key={i}
                        className={`paginate_button d-flex align-items-center justify-content-center ${
                            currentPage === number + 1 ? "current" : ""
                        } ${i > 0 ? "ml-1" : ""}`}
                        onClick={() => switchPage(number + 1)}
                        >
                        {number + 1}
                        </button>
                    ))}
                </span>

                {currentPage < total && <button
                    type="button"
                    className="paginate_button next disabled"
                    onClick={() =>switchPage(currentPage + 1)}
                >
                    Next
                </button>}
            </div>
        </div>
    );
};

export default AppointmentPagination;