import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useParams } from "react-router-dom";
import { Tab, Nav, ListGroup } from "react-bootstrap";
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import { getClientConsultationsAction } from '../../../../store/actions/ConsultationActions';
import {format} from 'date-fns';


const HistoriqueMedical = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getClientConsultationsAction(id));
    }, []);


    return (
        <Fragment>
            <div className="row">
                <div className='col-12'>
                    <div className='card'>
                        <div className='card-header border-0 bg-primary d-flex justify-content-between'>
                            <h4 className='text-white d-flex align-items-center'>
                                <i className='la la-list-alt mx-1' style={{fontSize: "25px"}}></i>
                                Historique de consultations
                            </h4>
                            <div className="input-group search-area d-inline-flex" style={{width: "40%"}}>
                                <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                                />
                                <div className="input-group-append">
                                    <button type="button" className="input-group-text">
                                        <i className="la la-search" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                        <PerfectScrollbar
                            style={{ height: '500px' }}
                            id='DZ_W_TimeLine1'
                            className='widget-timeline dz-scroll style-1 height500 ps ps--active-y'
                        >
                            <ul className='timeline'>
                                {props.consultations.map(consultation => {
                                return <li>
                                    <div className={consultation.id % 2 ? 'timeline-badge info' :  (
                                        consultation.id % 3 ? 'timeline-badge success' : (
                                            consultation.id % 5 ? 'timeline-badge warning' :(
                                                consultation.id % 7 ? 'timeline-badge danger' : 'timeline-badge primary'
                                            )
                                        )
                                    )}></div>
                                    <Link
                                        className='timeline-panel text-muted'
                                        to={"/consultation-details/"+consultation.id}
                                    >
                                        <span>{format(new Date(consultation.openDate), 'dd/MM/yyyy hh:mm')}</span>
                                        <h6 className='mb-0'>
                                            {consultation.motif}
                                        </h6>
                                    </Link>
                                </li>})
                                }
                            </ul>
                        </PerfectScrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        provider: state.provider.provider,
        consultations: state.consultation.clientConsultations,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(HistoriqueMedical);