import {
    START_LOADER_ACTION,
    GET_FACTURES_CONFIRMED_ACTION,
    GET_FACTURES_FAILED_ACTION,
    GET_FACTURE_CONFIRMED_ACTION,
    GET_FACTURE_FAILED_ACTION,
    GET_MOTIFS_EXCLU_CONFIRMED_ACTION,
    GET_MOTIFS_EXCLU_FAILED_ACTION,
    SAVE_REJET_FACTURE_CONFIRMED_ACTION,
    SAVE_REJET_FACTURE_FAILED_ACTION,
    RESTORE_REJET_FACTURE_CONFIRMED_ACTION,
    RESTORE_REJET_FACTURE_FAILED_ACTION,
    REQUEST_REMBOURSEMENT_CONFIRMED_ACTION,
    REQUEST_REMBOURSEMENT_FAILED_ACTION,
    GET_REMBOURSEMENTS_CONFIRMED_ACTION,
    GET_REMBOURSEMENTS_FAILED_ACTION,
    GET_REMBOURSEMENT_CONFIRMED_ACTION,
    GET_REMBOURSEMENT_FAILED_ACTION,
    UPDATE_REMBOURSEMENT_CONFIRMED_ACTION,
    UPDATE_REMBOURSEMENT_FAILED_ACTION
} from '../actions/FacturationAction';

const initialState = {
    factures: [],
    facture: {},
    motifExclusion: [],
    demandeRembs: [], 
    remboursements: [],
    remboursement: {},
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const FacturationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FACTURES_CONFIRMED_ACTION:
            return {
                ...state,
                factures: action.payload,
                errorMessage: '',
                successMessage: 'Factures gotten Successfully Completed',
                showLoading: false,
            };
        case GET_FACTURES_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_FACTURE_CONFIRMED_ACTION:
            return {
                ...state,
                facture: action.payload,
                errorMessage: '',
                successMessage: 'Facture gotten Successfully Completed',
                showLoading: false,
            };
        case GET_FACTURE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case GET_MOTIFS_EXCLU_CONFIRMED_ACTION:
            return {
                ...state,
                motifExclusion: action.payload,
                errorMessage: '',
                successMessage: 'Exclusions gotten Successfully Completed',
                showLoading: false,
            };
        case GET_MOTIFS_EXCLU_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        
        case RESTORE_REJET_FACTURE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'Resjet restoration Successfully Completed',
                showLoading: false,
            };
        case RESTORE_REJET_FACTURE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
            
        case SAVE_REJET_FACTURE_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'reject saving Successfully Completed',
                showLoading: false,
            };
        case SAVE_REJET_FACTURE_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
            
        case REQUEST_REMBOURSEMENT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'request remboursement Successfully Completed',
                showLoading: false,
            };
        case REQUEST_REMBOURSEMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
            
        case GET_REMBOURSEMENTS_CONFIRMED_ACTION:
            return {
                ...state,
                remboursements: action.payload,
                errorMessage: '',
                successMessage: 'Remboursements gotten Successfully Completed',
                showLoading: false,
            };
        case GET_REMBOURSEMENTS_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case GET_REMBOURSEMENT_CONFIRMED_ACTION:
            return {
                ...state,
                remboursement: action.payload,
                errorMessage: '',
                successMessage: 'Remboursement gotten Successfully Completed',
                showLoading: false,
            };
        case GET_REMBOURSEMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };

        case UPDATE_REMBOURSEMENT_CONFIRMED_ACTION:
            return {
                ...state,
                errorMessage: '',
                successMessage: 'update remboursement Successfully Completed',
                showLoading: false,
            };
        case UPDATE_REMBOURSEMENT_FAILED_ACTION:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
                showLoading: false,
            };
        case START_LOADER_ACTION:
            return {
                ...state,
                showLoading: true,
            };
        default:
            return state;
    };
};