import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { loadingToggleAction,loginAction,preLoginAction,logout
} from '../../store/actions/AuthActions';

//
import logo from '../../images/logo.png'
import logotext from '../../images/logo-text.png'
import logoActiva from '../../images/logo-activa.png'
import { Button, Spinner } from 'react-bootstrap'
import "./Login.css"
import { useTranslation } from 'react-i18next';
import Lang from '../layouts/nav/lang';

function Login (props) {
	const {t} = useTranslation();
    const [email, setEmail] = useState('');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
	const [verifCode, setVerifCode] = useState('');
	const [onCodeCheck, setOnCodeCheck] = useState(false);
	const [trials, setTrials] = useState(0);
    const dispatch = useDispatch();

	console.log(props.showLoading);

    function onLogin(e) {
        e.preventDefault();
		if(onCodeCheck) {
			setTrials(trials + 1);
			let error = false;
			const errorObj = { ...errorsObj };
			if (verifCode.trim().length === 0) {
				errorObj.code = 'Code is Required';
				error = true;
			}else{
				let savedCode = props.authData.code;
				if(verifCode !== savedCode){
					errorObj.code = 'The code is incorrect, please try again';
					error = true;
				}
			}
			setErrors(errorObj);
			if (error) {
				if(trials + 1 > 3) {
					setOnCodeCheck(false);
					setTrials(0);
					setVerifCode('');
					dispatch(logout(props.history));
				}
				return ;
			}
			dispatch(loadingToggleAction(true));	
			dispatch(loginAction({...props.authData, code: '', isCodeLogged: true}, props.history));

		}else{
			let error = false;
			const errorObj = { ...errorsObj };
			if (email === '') {
				errorObj.email = 'Phonenumber or Insured number is Required';
				error = true;
			}
			if (password === '') {
				errorObj.password = 'Password is Required';
				error = true;
			}
			setErrors(errorObj);
			if (error) {
				return ;
			}
			dispatch(loadingToggleAction(true));	
			dispatch(preLoginAction(email, password));
		}
    }

	useEffect(() => {
        console.log("Inside use effect");
        if(props.authData.token.length > 0) {
			setOnCodeCheck(true);
		}
    }, [props.authData]);

  return (
  
		<div className="login-form-bx">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-6 col-md-7 box-skew d-flex">
						<div className="authincation-content">
							<Link to="/dashboard" className="login-logo">
								<div className='mb-3'><img src={logoActiva} alt=""  width={"300px"} className="logo-icon mr-2"/></div> 
								<img src={logotext} alt="" className="logo-text ml-1"/>
							</Link>
							<div className="mb-4">
								<h3 className="mb-1 font-w600">{t('dashboard.welcome')}</h3>
								<p className="">{t('dashboard.login-message')}</p>
							</div>
							{props.errorMessage && (
								<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
									{props.errorMessage}
								</div>
							)}
							{props.successMessage && (
								<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
									{props.successMessage}
								</div>
							)}
							<form onSubmit={onLogin}>
								{!onCodeCheck && 
								<>
									<div className="form-group">
										<label className="mb-2 ">
											<strong className="">{t('layout.username')}</strong>
										</label>
										<input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>
										{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
									</div>
									<div className="form-group">
										<label className="mb-2 "><strong className="">{t('layout.password')}</strong></label>
										<input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}/>
										{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
									</div>
									<div className="form-row d-flex justify-content-between mt-4 mb-2">
										<div className="form-group">
											<div className="custom-control custom-checkbox ml-1 ">
												<input type="checkbox" className="form-check-input" id="basic_checkbox_1"/>
												<label className="form-check-label" htmlFor="basic_checkbox_1">{t('layout.prefs')}</label>
											</div>
										</div>
									</div>
								</>}

								{onCodeCheck && 
								<div className="form-group">
									<label className="mb-2 ">
										<strong className="">{t('layout.verif-code')}</strong>
									</label><br/>
									<small>{3 - trials} {t('patient.remain-attempt')}</small>
									<input type="text" maxLength={4} className="form-control" value={verifCode} onChange={(e) => setVerifCode(e.target.value)}/>
									{errors.code && <div className="text-danger fs-12">{errors.code}</div>}
								</div>}

								<div className="text-center">
									
									{ props.showLoading && 
										<Button variant="primary" disabled>
											<Spinner
												as="span"
												animation="grow"
												size="sm"
												role="status"
												aria-hidden="true"
											/>{' '}
											<span className="visually-hidden">Un instant...</span>
										</Button> }
									{ !props.showLoading &&
										<button type="submit" className="btn btn-primary btn-block">{t('layout.password')}</button>
									}
									
								</div>
								<div className="text-center mt-4">
									<Link className='text-primary' to='/help'>
									{t('layout.help-center')}
									</Link>
								</div>
							</form>
							<div className='my-2'>
								<Lang />
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-5 d-flex box-skew1">

					</div>
				</div>
			</div>
		</div>
		
    )
}

const mapStateToProps = (state) => {
    return {
		authData: state.auth.auth,
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);