import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useParams } from "react-router-dom";
import { Tab, Nav, ListGroup, Button } from "react-bootstrap";
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import { getParametersHistoryAction } from '../../../../store/actions/ConsultationActions';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from 'react-select';
import ReactApexChart from "react-apexcharts";
import {format} from 'date-fns';

const Parametres = (props) => {
    const { datas, paramsHistory } = props;
    const dispatch = useDispatch();

    const parameters = [
        {value: "tous", label: "Tous"},
        {value: "Taille", label: "Taille"},
        {value: "Poids", label: "Poids"},
        {value: "Température", label: "Température"},
        {value: "IMC", label: "IMC"},
        {value: "Tension S", label: "Tension S"},
        {value: "Tension D", label: "Tension D"},
    ];

    let currentDate = new Date();
    const [start, setStart] = useState(new Date('01/01/'+currentDate.getFullYear()));
    const [end, setEnd] = useState(currentDate);
    const [serie, setSerie] = useState(parameters[0]);

    const options = {
        chart: {
           height: 350,
           type: "area",
           group: "social",
           toolbar: {
              show: false,
           },
           zoom: {
              enabled: false,
           },
        },
        dataLabels: {
           enabled: false,
        },
        stroke: {
           width: [2, 2],
           colors: ["#24439D", "#A336C9", "#2bc155", "#369DC9", "#ffb800", "#F46B68"],
           curve: "straight",
        },
        legend: {
           tooltipHoverFormatter: function (val, opts) {
              return (
                 val +
                 " - " +
                 opts.w.globals.series[opts.seriesIndex][
                    opts.dataPointIndex
                 ] +
                 ""
              );
           },
           markers: {
              colors: ["#24439D", "#A336C9", "#2bc155", "#369DC9", "#ffb800", "#F46B68"],
              width: 19,
              height: 19,
              strokeWidth: 0,
              radius: 19,
           },
        },
        markers: {
           size: 6,
           border: 0,
           colors: ["#24439D", "#A336C9", "#2bc155", "#369DC9", "#ffb800", "#F46B68"],
           hover: {
              size: 6,
           },
        },
        yaxis: {
           labels: {
              style: {
                 colors: "#3e4954",
                 fontSize: "14px",
                 fontFamily: "Poppins",
                 fontWeight: 100,
              },
           },
        },
        fill: {
           colors: ["#24439D", "#A336C9", "#2bc155", "#369DC9", "#ffb800", "#F46B68"],
           type: "solid",
           opacity: 0.07,
        },
        grid: {
           borderColor: "#f1f1f1",
        },
     }

    
    const formatPeriods = (dataSeries) => {
        return {...options, xaxis: {categories: dataSeries.periodes}};
    }

    const loadSerie = (dataSeries, serieName) => {
        let allSeries = [
            {
                name: "Taille",
                data: dataSeries.taille,
            },
            {
                name: "Poids",
                data: dataSeries.poids,
            },
            {
                name: "IMC",
                data: [2,1.5,2,2,2,3,4,5,4.5,4.2,3,3.5],
            },
            {
                name: "Température",
                data: dataSeries.temperature,
            },
            {
                name: "Tension S",
                data: dataSeries.tensionS,
            },
            {
                name: "Tension D",
                data: dataSeries.tensionD,
            }
        ];
        
        return allSeries.filter(serie => {return serie.name === serieName})
    }


    const formatSeries = (dataSeries) => {
        return [
            {
                name: "Taille",
                data: dataSeries.taille,
            },
            {
                name: "Poids",
                data: dataSeries.poids,
            },
            {
                name: "IMC",
                data: [2,1.5,2,2,2,3,4,5,4.5,4.2,3,3.5],
            },
            {
                name: "Température",
                data: dataSeries.temperature,
            },
            {
                name: "Tension S",
                data: dataSeries.tensionS,
            },
            {
                name: "Tension D",
                data: dataSeries.tensionD,
            }
        ]
    }

    const searchPeriod = () => {
        dispatch(getParametersHistoryAction(format(new Date(start), 'dd-MM-yyyy'), format(new Date(end), 'dd-MM-yyyy')));
    }

    
    useEffect(() => {
        dispatch(getParametersHistoryAction(format(new Date(start), 'dd-MM-yyyy'), format(new Date(end), 'dd-MM-yyyy')));
    }, []);


    return (
        <Fragment>
            <div className="row">
                <div className='col-12 p-0 pr-2 m-0'>
                    <div className='card' style={{width: "100%" }}>
                        <div className='card-header border-0 bg-primary'>
                            <h4 className='text-white d-flex align-items-center'>
                                <i className='la la-chart-bar mx-1' style={{fontSize: "25px"}}></i>
                                Mesures
                            </h4>
                        </div>
                        <div className='card-body'>
                        <PerfectScrollbar
                            style={{ height: '185px', width: "100% !important" }}
                            id='DZ_W_TimeLine1'
                            className='widget-timeline dz-scroll style-1 height185 ps ps--active-y'
                        >
                            <div className='d-flex flex-wrap'>
                                {datas.listParametres[4] && <div className='col-3 p-0 m-0 pr-2 mb-3 align-items-start'>
                                    <div className='p-1 d-flex flex-column border-bottom' style={{width: "70%"}}>
                                        <h4 className='text-primary mb-2'>Taille</h4>
                                        <b>{datas.listParametres[4]?.value} m</b>
                                    </div>
                                    <small className='text-dark'>{format(new Date(datas.listParametres[4]?.date), 'dd/MM/yyyy')}</small>
                                </div>}
                                {datas.listParametres[5] && <div className='col-3 p-0 m-0 pr-2 mb-3 align-items-start'>
                                    <div className='p-1 d-flex flex-column border-bottom' style={{width: "70%"}}>
                                        <h4 className='text-primary mb-2'>Poids</h4>
                                        <b>{datas.listParametres[5]?.value} Kg</b>
                                    </div>
                                    <small className='text-dark'>{format(new Date(datas.listParametres[5]?.date), 'dd/MM/yyyy')}</small>
                                </div>}
                                {datas.listParametres[0] && <div className='col-3 p-0 m-0 pr-2 mb-3 align-items-start'>
                                    <div className='p-1 d-flex flex-column border-bottom' style={{width: "70%"}}>
                                        <h4 className='text-primary mb-2'>IMC</h4>
                                        <b>{datas.listParametres[0]?.value} kg/m</b>
                                    </div>
                                    <small className='text-dark'>{format(new Date(datas.listParametres[0]?.date), 'dd/MM/yyyy')}</small>
                                </div>}
                                {datas.listParametres[3] && <div className='col-3 p-0 m-0 pr-2 mb-3 align-items-start'>
                                    <div className='p-1 d-flex flex-column border-bottom' style={{width: "70%"}}>
                                        <h4 className='text-primary mb-2'>Temp</h4>
                                        <b>{datas.listParametres[3]?.value} °C</b>
                                    </div>
                                    <small className='text-dark'>{format(new Date(datas.listParametres[3]?.date), 'dd/MM/yyyy')}</small>
                                </div>}
                                {datas.listParametres[2] && <div className='col-3 p-0 m-0 pr-2 mb-3 align-items-start'>
                                    <div className='p-1 d-flex flex-column border-bottom' style={{width: "70%"}}>
                                        <h4 className='text-primary mb-2'>Tension S</h4>
                                        <b>{datas.listParametres[2]?.value} mm/Hg</b>
                                    </div>
                                    <small className='text-dark'>{format(new Date(datas.listParametres[2]?.date), 'dd/MM/yyyy')}</small>
                                </div>}
                                {datas.listParametres[1] && <div className='col-3 p-0 m-0 pr-2 mb-3 align-items-start'>
                                    <div className='p-1 d-flex flex-column border-bottom' style={{width: "70%"}}>
                                        <h4 className='text-primary mb-2'>Tension D</h4>
                                        <b>{datas.listParametres[1]?.value} mm/Hg</b>
                                    </div>
                                    <small className='text-dark'>{format(new Date(datas.listParametres[1]?.date), 'dd/MM/yyyy')}</small>
                                </div>}
                            </div>
                        </PerfectScrollbar>
                        <div className='py-2'>
                            <div className='filter row justify-content-between p-2'>
                                <div className='col-2 mb-1'>
                                    <label className='mb-1 '>
                                        <strong>Type de parametre</strong>
                                    </label>
                                    <Select
                                        selected={serie}
                                        onChange={setSerie}
                                        options={parameters}
                                        style={{
                                        lineHeight: '40px',
                                        color: '#7e7e7e',
                                        paddingLeft: ' 15px',
                                        }}
                                    />
                                </div>

                                <div className='col-6 mb-1'>
                                    <div className='row justify-space-between align-items-center'>
                                        <div className='col-4 mb-1'>
                                            <label className='mb-1 '>
                                                <strong>Début</strong>
                                            </label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk
                                                    label=""
                                                    format="dd/MM/yyyy hh:mm"
                                                    value={start}
                                                    disableFuture
                                                    maxDate={end}
                                                    //disabled={showLoading}
                                                    onChange={setStart}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        
                                        <div className='col-4 mb-1'>
                                            <label className='mb-1 '>
                                                <strong>Fin</strong>
                                            </label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk
                                                    label=""
                                                    format="dd/MM/yyyy hh:mm"
                                                    value={end}
                                                    disableFuture
                                                    minDate={start}
                                                    //disabled={showLoading}
                                                    onChange={setEnd}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className='col-2 mb-1'>
                                            <Button
                                            variant='primary'
                                            target="_blank"
                                            className='btn-card mt-3 mr-3'
                                            onClick={(e) => searchPeriod()}
                                            >
                                                <i className="la la-search"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="chart">
                                <ReactApexChart
                                options={formatPeriods(paramsHistory)}
                                series={serie.value === 'tous' ? formatSeries(paramsHistory) : loadSerie(paramsHistory, serie.value)}
                                type="area"
                                height={400}
                                />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        paramsHistory: state.consultation.paramsHistory,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(Parametres);