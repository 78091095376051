import React, {useState, useEffect} from 'react'
import { useDispatch, connect } from 'react-redux';
import { Accordion, AccordionTab } from 'primereact/accordion';
import {sentEmailMessage, loadingToggleAction} from '../../store/actions/HelpActions';
import { Button, Spinner } from 'react-bootstrap';
import "./Help.css"

const Help = (props) => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('Général');
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        let messageContent = "Bonjour "+(subject === 'Support Technique' ? 
        process.env.REACT_APP_IT_SUPPORT_NAME :
        (subject === 'Support Médical' ? 
            process.env.REACT_APP_MEDICAL_SUPPORT_NAME : 
            process.env.REACT_APP_SUPPORT_NAME
        ))+",\r\n Vous avez un nouveau message de l'application A-Care. \r\n"+message+"\r\n"+name+"\r\n"+email;

        let formData = {
            subject,
            message: messageContent,
            email: subject === 'Support Technique' ? 
                process.env.REACT_APP_IT_SUPPORT_EMAIL :
                (subject === 'Support Médical' ? 
                    process.env.REACT_APP_MEDICAL_SUPPORT_EMAIL : 
                    process.env.REACT_APP_SUPPORT_EMAIL
                ),
        };

        console.log(formData);
        dispatch(loadingToggleAction(true));
        dispatch(sentEmailMessage(formData));
    };

    
    useEffect(() => {
        if(props.successMessage.trim().length > 0) {
            setName('');
            setEmail('');
            setSubject('Général');
            setMessage('');
        }
    }, [props.successMessage]);

    return (
        <div className='bg-white p-4'>
            <h3>Centre d'aide</h3>
            <div className="container-help">
                <div className='row' style={{width: "100%"}}>
                    <div className='col-6'>
                        <Accordion activeIndex={0}>
                            <AccordionTab header="Comment faire quand je reçois le message de la plateforme pour la première fois?">
                                <p className="m-0">
                                    Cliquez sur le lien reçu par sms ou par mail  pour se connecter à l’espace assuré
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment avoir la nouvelle plateforme santé d'activa?">
                                <p className="m-0">
                                    Rendez-vous dans nos zones de santé
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Mot de passe oublié ! // comment faire quand on a oublié le mot de passe ?">
                                <p className="m-0">
                                    Contacter le support technique (par mail ou par téléphone) - la fonctionnalité mot de passe perdu n’est pas disponible pour le moment
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Adresse email incorrect ! // comment faire quand on a oublié l'adresse mail ?">
                                <p className="m-0">
                                    Contacter le support technique (par mail ou par téléphone) - la fonctionnalité mot de passe perdu n’est pas disponible pour le moment
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment faire pour prendre un RDV avec le médecin?">
                                <p className="m-0">
                                    Premier clique sur le “ Menu ”, deuxième clique sur ” Rendez-Vous ”, troisième clique sur  “ Nouveau Rendez-Vous ”, remplissez la fiche et enfin envoyer la demande.
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment faire pour trouver l'hôpital le plus proche ?">
                                <p className="m-0">
                                    Cliquer sur Prestataire ensuite Carte des Prestataires et commencer la géolocalisation.  
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment vérifier si ma demande de remboursement a été traitée ?">
                                <p className="m-0">
                                    Vérifier le statut de la demande de remboursement.
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Quels sont les documents dont j'ai besoin pour une demande de remboursement?">
                                <p className="m-0">
                                    Les différentes copies des Facture proforma ainsi qu’une lettre de demande de remboursement.
                                </p>
                            </AccordionTab>
                            <AccordionTab header="Comment modifier une erreur dans mes informations de facturation? ">
                                <p className="m-0">
                                    Prière de vous présenter chez votre assureur. 
                                </p>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div className='col-6'>
                        <div className="contact-form">
                            <h2>Formulaire de contact</h2>
                            
                            <form onSubmit={e => sendEmail(e)}>
                                <label for="name">Nom:</label>
                                <input type="text" id="name" value={name} onChange={e => setName(e.target.value)} required />

                                <label for="email">Email:</label>
                                <input type="email" id="email" value={email} onChange={e => setEmail(e.target.value)} required />

                                <label for="topic">Sujet:</label>
                                <select id="topic" name="topic" onChange={e => setSubject(e.target.value)} required>
                                    <option selected={subject === "Général"} value="Général">Général</option>
                                    <option selected={subject === "Facturation et paiement"} value="Facturation et paiement">Facturation et paiement</option>
                                    <option selected={subject === "Support Médical"} value="Support Médical">Support Médical</option>
                                    <option selected={subject === "Support Technique"} value="Support Technique">Support Technique</option>
                                    <option selected={subject === "Avis et suggestions"} value="Avis et suggestions">Avis et suggestions</option>
                                </select>

                                <label for="message">Message:</label>
                                <textarea id="message" value={message} onChange={e => setMessage(e.target.value)}  required></textarea>

                                { props.showLoading && 
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{' '}
                                        <span className="visually-hidden">Un instant...</span>
                                    </Button> }
                                { !props.showLoading &&
                                    <button type="submit" className="btn btn-primary btn-block">Envoyer</button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="phone-numbers">
                <h2>Contactez-nous</h2>
                <p>Pour toute assistance, appellez-nous au:</p>
                <ul>
                    <li> (+243) 0 815 570 000</li>
                </ul>
                <p>
                    Vous pouvez aussi nous contacter via {' '}
                    <a className='text-primary text-underline' href="https://wa.me/2430900000567">WhatsApp</a>
                </p>
            </div>
        </div> 
    )
}
const mapStateToProps = (state) => {
    return {
       successMessage: state.help.successMessage,
       errorMessage: state.help.errorMessage,
       showLoading: state.help.showLoading,
    };
};
export default connect(mapStateToProps)(Help);