import { getBonByCode, sendProforma, executeBon, getBonDataByCode, addActeBonKine} from '../../services/KineService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_BON_CONFIRMED_ACTION = '[getKineBon action] confirmed getKineBon';
export const GET_BON_FAILED_ACTION = '[getKineBon action] failed getKineBon';

export const GET_BON_DATA_CONFIRMED_ACTION = '[getKineBonData action] confirmed getKineBonData';
export const GET_BON_DATA_FAILED_ACTION = '[getKineBonData action] failed getKineBonData';

export const SEND_PROFORMA_CONFIRMED_ACTION = '[getSendKineProforma action] confirmed getSendKineProforma';
export const SEND_PROFORMA_FAILED_ACTION = '[getSendKineProforma action] failed getSendKineProforma';

export const EXECUTE_ACT_CONFIRMED_ACTION = '[executeKineAct action] confirmed executeKineAct';
export const EXECUTE_ACT_FAILED_ACTION = '[executeKineAct action] failed executeKineAct';

export const START_LOADER_ACTION = '[kineLoader action] started kineLoader';

var I18n = require('react-redux-i18n').I18n;

export const getBonByCodeAction = (code, providerId) => {
    console.log("Action is called");
    return (dispatch) => {
        getBonByCode(code, providerId)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedGetBonAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('labo.get_bon_error')+error.response?.data?.message);
            dispatch(getBonFailedAction(error.response?.data?.message));
        });
    };
};

export const getBonDataByCodeAction = (code) => {
    console.log("Action is called");
    return (dispatch) => {
        getBonDataByCode(code)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedGetBonDataAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('labo.get_bon_error')+error.response?.data?.message);
            dispatch(getBonDataFailedAction(error.response?.data?.message));
        });
    };
};


export const sendProformaAction = (data) => {
    console.log("Action is called");
    return (dispatch) => {
        sendProforma(data)
        .then((response) => {
            console.log(response.data);
            successToastr(I18n.t('optic.success_proforma_send'));
            dispatch(confirmedSendProformaAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('optic.error_proforma_send')+error.response?.data?.message);
            dispatch(sendProformaFailedAction(error.response?.data?.message));
        });
    };
};

export const executeBonAction = (data) => {
    console.log("Action is called");
    return (dispatch) => {
        executeBon(data)
        .then((response) => {
            console.log(response.data);
            successToastr(I18n.t('optic.success_exec_bon'));
            dispatch(confirmedExecuteBonAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('optic.error_exec_bon')+error.response?.data?.message);
            dispatch(executeBonFailedAction(error.response?.data?.message));
        });
    };
};

export const addKineActeBonAction = (data) => {
    console.log("Action is called");
    return (dispatch) => {
        addActeBonKine(data)
        .then((response) => {
            console.log(response.data);
            successToastr(I18n.t('kine.success_add_kine_acte'));
        })
        .catch((error) => {
            errorToastr(I18n.t('kine.error_add_kine_acte')+error.response?.data?.message);
        });
    };
};

export function confirmedExecuteBonAction(payload) {
    return {
        type: EXECUTE_ACT_CONFIRMED_ACTION,
        payload,
    };
};

export function executeBonFailedAction(payload) {
    return {
        type: EXECUTE_ACT_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetBonDataAction(payload) {
    return {
        type: GET_BON_DATA_CONFIRMED_ACTION,
        payload,
    };
};

export function getBonDataFailedAction(payload) {
    return {
        type: GET_BON_DATA_FAILED_ACTION,
        payload,
    };
};

export function confirmedGetBonAction(payload) {
    return {
        type: GET_BON_CONFIRMED_ACTION,
        payload,
    };
};

export function getBonFailedAction(payload) {
    return {
        type: GET_BON_FAILED_ACTION,
        payload,
    };
};

export function confirmedSendProformaAction(payload) {
    return {
        type: SEND_PROFORMA_CONFIRMED_ACTION,
        payload,
    };
};

export function sendProformaFailedAction(payload) {
    return {
        type: SEND_PROFORMA_FAILED_ACTION,
        payload,
    };
};



export function startLoader() {
    return {
        type: START_LOADER_ACTION
    };
};