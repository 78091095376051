import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

export const getBonByCode = (code) => consultationServiceRequest.get('/ActeSpecialise/bon/'+code);
export const markActeDone = (data) => consultationServiceRequest.post('/ActeSpecialise/make/done', data);
