export const STATUSES = {
    success: "202",
    failed: "500",
    resused: "403",
    cancel: "400",
    confirmed: "203",
    pending: "102",
    created: "201",
    accepted: "202",
    exectuted: "200",
    hospitalisation: "100",
    nonPaye: "104",
    paye: "205",
    enCoursPaiement: "103",
    delivered: "203",
    rejeted: "403",
    waitingAccordPreable: "106",
    expired: "405"
};