import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import PieChart from '../chart/PieChart';
import './EtatsGlobaux.css';

const EtatsGlobaux = (props) => {
    const { t } = useTranslation();
    const {data, showLoading} = props;

    const [dataSeries, setDataSeries] = useState([]);
    const [dataLabels, setDataLabels] = useState([]);
    const [graph, setGraph] = useState(false);

    useEffect(() => {
        console.log(data);
        if(Object.keys(data).length > 0){
            console.log("Inside the if");
            console.log(data);
            setDataSeries([parseFloat(data.totalRejete), parseFloat(data.totalPaye)]);
            setDataLabels(["Montant Rejeté", "Montant Payé"]);
        }
    }, [data]);

    return (
            <>
                {Object.keys(data).length === 0 && <span>
                    Aucune donnée à afficher, veuiller remplir les filtres afin d'en avoir.
                </span>
                }
                {Object.keys(data).length > 0 && <div className='results-section'>
                    <div className='row justify-content-center my-4'>
                        <div className='form-group mb-0'>
                            <label className='radio-inline mr-5'>
                                <input type='radio' name='graph' value="hideGraph" checked={!graph} onChange={e => {setGraph(!e.target.checked)}}/> Données
                            </label>
                            <label className='radio-inline'>
                                <input type='radio' name='graph' value="showGraph" checked={graph} onChange={e => {setGraph(e.target.checked)}}/> Graphique
                            </label>
                        </div>
                    </div>
                    {!graph && <ul>
                        <li>
                            <h4>Montant total des prestations:</h4>
                            <span>{data.totalPrestations}</span>
                        </li>
                        <li>
                            <h4>Montant total rejeté:</h4>
                            <span>{data.totalRejete}</span>
                        </li>
                        <li>
                            <h4>Montant total à payer:</h4>
                            <span>{data.totalPaye}</span>
                        </li>
                        <li>
                            <h4>Nombre total de prestataires:</h4>
                            <span>{data.nbrePrestataire}</span>
                        </li>
                        <li>
                            <h4>Nombre total de visites:</h4>
                            <span>{data.nbreVisite}</span>
                        </li>
                    </ul>}
                    {graph && <PieChart series={dataSeries} pieWidth="380" labels={dataLabels} legendPosition="bottom" />}
                </div>}
            </> 
    )
}
export default EtatsGlobaux;