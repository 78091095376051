import React, { Fragment,useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Row, Col, Card, Tab, Nav, Spinner } from 'react-bootstrap'
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import { 
    getCollegePerContractAction, 
    getFamillePerCollegeAction, 
    getContractPerClientAction, 
    getBeneficiairesAction, 
    loadingToggleAction,
    requestAvenantAction 
} from '../../../store/actions/ContratActions';
import Select from 'react-select';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DataTableBase } from "../DataTables/DataTableBase";
import { Calendar } from 'primereact/calendar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './ListAssure.css';
import IncorporationModal from './IncorporationModal';
import ChangementCollegeModal from './ChangementCollegeModal';

const ListAssures = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [policeId, setPoliceId] = useState('');
    const [collegeId, setCollegeId] = useState('');
    const [familleId, setFamilleId] = useState('');
    const [numAdhPrinc, setNumAdhPrinc] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [selectedAssures, setSelectedAssures] = useState([]);
    const [currentAssure, setCurrentAssure] = useState(null);
    const [currentTitle, setCurrentTitle] = useState(null);
    const [visible, setVisible] = useState(false);
    const [visibleConfirmRetrait, setVisibleConfirmRetrait] = useState(false);
    const [visibleContratAvenant, setVisibleContratAvenant] = useState(false);
    const [visibleChangementCollege, setVisibleChangementCollege] = useState('');

    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const {currentUser, contratPerClient, famillePerCollege, collegePerContrat, beneficiaires} = props;

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            nom: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            sexe: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            telephone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            numeroPolice: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateNaissance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            dateExpiration: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash"  label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => onGlobalFilterChange(e)}  placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const setColumns = () => {
        return [
            {selectionMode: "multiple", style: { width: '3rem' }},
            {name: 'nom', headerName: t('common.name'), filterPlaceholder: t('common.filter-name'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'sexe', headerName: t('common.gender'), filterPlaceholder: t('common.filter-sex'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'telephone', headerName: t('common.phone'), filterPlaceholder: t('common.phone-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'email', headerName: t('client.email'), filterPlaceholder: t('common.email-filter'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'numeroPolice', headerName: t('common.numpolice'), filterPlaceholder: t('common.filter-policenum'), style: { minWidth: '12rem', fontSize: '14px' }, filter: true, bodyTemplate: null, sortable: true},
            {name: 'dateNaissance', filterField: 'dateNais', dataType: 'date', headerName: t('common.birthdate'), filterPlaceholder: t('common.filter-datenaiss'), style: { minWidth: '6rem', fontSize: '14px' }, filter: true, bodyTemplate: dateNaissBodyTemplate, filterElement: dateFilterTemplate, sortable: true},
            {name: 'actions', headerName: 'Actions', filterPlaceholder: '', style: { minWidth: '6rem', fontSize: '14px'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            <button className="btn btn-info shadow btn-xs sharp mr-2"
                onClick={e => console.log(e)}
            >
                <i className="fa fa-eye"></i>
            </button>
            <button className="btn btn-primary shadow btn-xs sharp mr-2"
                onClick={e => {
                    setVisibleChangementCollege(rowData.id);
                    setCurrentAssure(rowData);
                }}
            >
                <i className="fa fa-pencil"></i>
            </button>
        </div>
    }

    const dateNaissBodyTemplate = (rowData) => {
        return rowData.dateNais ? formatDate(rowData.dateNais) : formatDate(rowData.dateNaissance);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    /*useEffect(() => {
        dispatch(getContractPerClientAction(currentUser.souscripteur.codeAssu));
    }, []);*/

    useEffect(() => {
        if(hasMenu('MENRHVIEWPOLICE') && canDo('MENRHVIEWPOLICE', 'RHLISTASSURES')){
            console.log('OK')
        }else{
            props.history.push('/access-denied');
        } 
    }, []); 

    useEffect(() => {
        let cumulB = [];

        props.beneficiaires.forEach(element => {
            cumulB = [...cumulB, ...element.beneficiaires];
        });

        if(collegeId && numAdhPrinc) {
            setFilteredResults(cumulB.filter(assur => {return (assur.numBeneficiairePrincipal+"" === numAdhPrinc+"") && (assur.numCollege+"" === collegeId+"")}) || []);
        }else if(numAdhPrinc) {
            setFilteredResults(cumulB.filter(assur => {return assur.numBeneficiairePrincipal+"" === numAdhPrinc+""}) || []);
        }else if(collegeId){
            setFilteredResults(cumulB.filter(elt => elt.numCollege+"" === collegeId+""));
        }else{
            setFilteredResults(cumulB);
        }
    }, [collegeId, numAdhPrinc]);

    useEffect(() => {
        if(beneficiaires.length > 0) {
            let cumulB = [];
            props.beneficiaires.forEach(element => {
                cumulB = [...cumulB, ...element.beneficiaires];
            });
            setFilteredResults(cumulB);
        }
    }, [props.beneficiaires]);

    const isDrugSelectable = (rowData) => {
        return true;
    }

    const footerContent = (
        <div>
            <Button label="Fermer" icon="pi pi-times" onClick={() => {
                setVisible(false);
                setVisibleConfirmRetrait(false);
                setVisibleChangementCollege('');
                setVisibleContratAvenant(false);
            }} className="p-button-text" />
        </div>
    );

    const requestRetrait = (e) => {
        e.preventDefault();

        let data = {
            codeAssu: currentUser.clientId,
            numPolice: policeId,
            numeroBeneficiaire: selectedAssures.map( elt => elt.numBeneficiaire)
        };

        console.log(data);
        dispatch(loadingToggleAction(true));
        dispatch(requestAvenantAction(data, 'retrait'));
    }

    const requestAvenant = (e) => {
        e.preventDefault();

        let data = {
            numeroPolice: policeId,
            codeAssu: currentUser.clientId
        };

        console.log(data);
        if(currentTitle === "Demande de suspension"){
            //dispatch(loadingToggleAction(true));
            //dispatch(requestAvenantAction(data, 'suspension'));
        }else if(currentTitle === "Demande d'annulation"){
            dispatch(loadingToggleAction(true));
            dispatch(requestAvenantAction(data, 'annulation'));
        }else{
            dispatch(loadingToggleAction(true));
            dispatch(requestAvenantAction(data, 'renouvellement'));
        }
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
            return false;
        }else{
            return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
    }
    
    const hasMenu = (menuCode) => {
        return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
    }

    return (
        <Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Body>
                            <div className='search-section'>
                                <form>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Police</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={currentUser.polices?.filter(elt => elt === policeId).map((opt)=>{return {label:opt,value:opt}})}
                                                    options={[{value: '', label: "Choisir"}, ...currentUser.polices?.map((opt)=>{return {label:opt,value:opt}})]}
                                                    placeholder="Choisir une police"
                                                    search
                                                    onChange={(val) => {
                                                        setPoliceId(val.value);
                                                        if(val.value) {
                                                            dispatch(getCollegePerContractAction(val.value));
                                                            dispatch(loadingToggleAction(true));
                                                            dispatch(getBeneficiairesAction(val.value));
                                                        }
                                                    }}
                                                    
                                                    style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Collège</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={collegePerContrat?.filter(elt => elt.numeroCollege === collegeId).map((opt)=>{return {label:opt.libelleGroupe,value:opt.numeroCollege}})}
                                                    options={[{value: '', label: "Choisir"}, ...collegePerContrat?.map((opt)=>{return {label:opt.libelleGroupe,value:opt.numeroCollege}})]}
                                                    placeholder="Choisir un collège"
                                                    search
                                                    
                                                    onChange={(val) => {
                                                        setCollegeId(val.value);
                                                        if(val.value) {
                                                            dispatch(loadingToggleAction(true));
                                                            dispatch(getFamillePerCollegeAction(val.value, policeId));
                                                        }
                                                    }}
                                                    style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {collegeId && <div className="col-lg-4 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Famille</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={famillePerCollege?.filter(elt => elt.id === familleId).map((opt)=>{return {label:opt.nom,value:opt.id, numbenef: opt.numBeneficiaire}})}
                                                    options={[{value: '', label: "Choisir"}, ...famillePerCollege?.map((opt)=>{return {label:opt.nom, value:opt.id, numbenef: opt.numBeneficiaire}})]}
                                                    placeholder="Choisir une famille"
                                                    search
                                                    
                                                    onChange={(val) => {
                                                        setFamilleId(val.value);
                                                        setNumAdhPrinc(val.numbenef);
                                                    }}
                                                    style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>}
                                        {!collegeId && <div className="col-lg-4 mb-2">
                                            <div className="form-group">
                                                <label className='mb-1 '>
                                                    <strong>Famille</strong>
                                                </label>
                                                <Select
                                                    className="select-search"
                                                    value={beneficiaires?.filter(elt => elt.assurePrinc.id === familleId).map((opt)=>{return {label:opt.assurePrinc.nom+' '+(opt.assurePrinc.prenoms || ''),value:opt.assurePrinc.id, numbenef: opt.numBeneficiaire}})}
                                                    options={[{value: '', label: "Choisir"}, ...beneficiaires?.map((opt)=>{return {label: opt.assurePrinc.nom+' '+(opt.assurePrinc.prenoms || ''),value:opt.assurePrinc.id, numbenef: opt.assurePrinc.numBeneficiaire}})]}
                                                    placeholder="Choisir une famille"
                                                    search
                                                    onChange={(val) => {
                                                        setFamilleId(val.value);
                                                        setNumAdhPrinc(val.numbenef);
                                                    }}
                                                    
                                                    style={{
                                                        lineHeight: '40px',
                                                        color: '#7e7e7e',
                                                        paddingLeft: ' 15px',
                                                    }}
                                                />
                                            </div>
                                        </div>}
                                    </div>
                                </form>
                            </div>

                            
                            <div className='row p-4 justify-content-between'>
                                <div className='row justify-content-end my-4'>
                                        { props.showLoading && 
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />{' '}
                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                            </Button> }
                                        { !props.showLoading &&
                                            <Button
                                                as='a'
                                                variant='primary light'
                                                href='#'
                                                className='btn-card'
                                                disabled={selectedAssures.length === 0}
                                                onClick={() => setVisibleConfirmRetrait(true)}
                                            >
                                                Retrait
                                            </Button> }
                                </div>
                                <div className='row justify-content-end my-4'>
                                    <Button
                                        as='a'
                                        href='#'
                                        onClick={() => setVisible(true)}
                                        variant='info light'
                                        disabled={!policeId}
                                        className='btn-card btn-info'
                                    >
                                        Incorporation
                                    </Button>
                                </div>
                                {/*<div className='row justify-content-end my-4'>
                                    <Button
                                        as='a'
                                        href='#'
                                        onClick={() => {
                                            setCurrentTitle('Demande de suspension');
                                            setVisibleContratAvenant(true);
                                        }}
                                        variant='info light'
                                        disabled={!policeId}
                                        className='btn-card btn-info'
                                    >
                                        Suspension
                                    </Button>
                                </div>*/}
                                <div className='row justify-content-end my-4'>
                                    <Button
                                        as='a'
                                        href='#'
                                        onClick={() => {
                                            setCurrentTitle('Demande d\'annulation');
                                            setVisibleContratAvenant(true);
                                        }}
                                        variant='info light'
                                        disabled={!policeId}
                                        className='btn-card btn-info'
                                    >
                                        Annulation
                                    </Button>
                                </div>
                                <div className='row justify-content-end my-4'>
                                    <Button
                                        as='a'
                                        href='#'
                                        onClick={() => {
                                            setCurrentTitle('Demande de renouvellement');
                                            setVisibleContratAvenant(true);
                                        }}
                                        variant='info light'
                                        disabled={!policeId}
                                        className='btn-card btn-info'
                                    >
                                        Renouvellement
                                    </Button>
                                </div>

                                <Dialog header="Demande d'incorporation" visible={visible} maximizable style={{ width: '50vw'}} onHide={() => setVisible(false)} footer={footerContent}>
                                    <IncorporationModal 
                                        polices={currentUser.polices}
                                        currentUser={currentUser}
                                    />
                                </Dialog>

                                <Dialog header="Changement de collège" visible={visibleChangementCollege === currentAssure?.id} maximizable style={{ width: '50vw'}} onHide={() => setVisibleChangementCollege('')} footer={footerContent}>
                                    <ChangementCollegeModal 
                                        assure={currentAssure}
                                        colleges={collegePerContrat}
                                        police={policeId}
                                        currentUser={currentUser}
                                    />
                                </Dialog>

                                <Dialog header={currentTitle} visible={visibleContratAvenant} maximizable style={{ width: '50vw'}} onHide={() => setVisibleContratAvenant(false)} footer={footerContent}>
                                    <form onSubmit={e => requestAvenant(e)}>
                                        <p>Confirmer la {currentTitle} sur la police {policeId}</p>

                                        <div className='row justify-content-center mt-4'>
                                            { props.showLoading && 
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">Un instant...</span>
                                                </Button> }
                                            { !props.showLoading &&
                                                <div className='col-4'>
                                                    <input type='submit' value="Confirmer" className='btn btn-primary btn-block'/>
                                                </div> }
                                        </div>
                                    </form>
                                </Dialog>

                                <Dialog header="Demande de retrait" visible={visibleConfirmRetrait} maximizable style={{ width: '50vw'}} onHide={() => setVisibleConfirmRetrait(false)} footer={footerContent}>
                                    <form onSubmit={e => requestRetrait(e)}>
                                        <p>Confirmer la demande de retrait de la police {policeId} des assuré</p>
                                        <ul>
                                            {selectedAssures.map(assure => {
                                                return <li>
                                                    {assure.nom} né(e) le {formatDate(assure.dateNaissance)}
                                                </li>
                                            })}
                                        </ul>
                                        <div className='row justify-content-center mt-4'>
                                            { props.showLoading && 
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    <span className="visually-hidden">Un instant...</span>
                                                </Button> }
                                            { !props.showLoading &&
                                                <div className='col-4'>
                                                    <input type='submit' value="Confirmer" className='btn btn-primary btn-block'/>
                                                </div> }
                                        </div>
                                    </form>
                                </Dialog>
                            </div>

                            <div className="row">
                                <div className="col-xl-12">
                                    <DataTableBase 
                                        data={ filteredResults.map(benef => {
                                            benef.dateNaissance = new Date(benef.dateNaissance);
                                            return benef;
                                        })}
                                        emptyMessage="Aucun bénéficiaire trouvé" 
                                        filters={filters}
                                        globalFiltersFields={['nom', 'sexe', 'telephone', 'email', 'numeroPolice', 'dateNaissance']}
                                        header={renderHeader()}
                                        columns={setColumns()}
                                        exportColumns={[
                                            { title: t('common.name'), dataKey: 'nom' },
                                            { title: t('common.gender'), dataKey: 'sexe' },
                                            { title: t('common.phone'), dataKey: 'telephone' },
                                            { title: 'Email', dataKey: 'email' },
                                            { title: t('common.numpolice'), dataKey: 'numeroPolice' },
                                            { title: t('common.family'), dataKey: 'dateNaissance' },
                                        ]}
                                        rows={10} 
                                        showExport={true}
                                        exportFilename={"beneficiaires"}
                                        loading={props.showLoading}
                                        lazy={false}
                                        rowClick={true}
                                        selectedData={selectedAssures}
                                        setSelectedDatas={(val) => setSelectedAssures(val)}
                                        isRowSelectable={val => isDrugSelectable(val)}
                                    />
                                    
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        currentUser: currentUser(state),
        collegePerContrat: state.contrat.collegePerContrat,
        famillePerCollege: state.contrat.famillePerCollege,
        contratPerClient: state.contrat.contratPerClient,
        souscripteurs: state.contrat.souscripteurs,
        beneficiaires: state.contrat.beneficiaires,
        showLoading: state.contrat.showLoading
        //showLoading: state.rapport.showLoading
    };
};
export default connect(mapStateToProps)(ListAssures);