import React, {useState} from "react";
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {format} from 'date-fns';
import { STATUSES } from '../../../globals';
import { useTranslation } from "react-i18next";

const AppointmentListItem = (props) => {
    const { t } = useTranslation();
    const { history, appointment } = props;


    console.log("this is the current appointment")
    console.log(appointment);

    const dispatch = useDispatch();

    const chackbox = document.querySelectorAll(".patient_checkbox input");
    const motherChackBox = document.querySelector(".patient_strg input");
    // console.log(document.querySelectorAll(".all_spending_strg input")[0].checked);
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
                if (motherChackBox.checked) {
                element.checked = true;
                } else {
                element.checked = false;
                }
            } else {
                if (!element.checked) {
                motherChackBox.checked = false;
                break;
                } else {
                motherChackBox.checked = true;
                }
            }
        }
    };

    const viewAppointment = () => {
        history.push('/appointment-view/'+appointment.id);
    }

    return (
        <tr role="row" className="odd">
            <td className="patient_checkbox">
                <div className="checkbox text-right align-self-center">
                    <div className="custom-control custom-checkbox ">
                    <input
                        type="checkbox"
                        onClick={() => chackboxFun()}
                        className="custom-control-input"
                        id="customCheckBox1"
                        required
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="customCheckBox1"
                    />
                    </div>
                </div>
            </td>
            <td>{appointment.providerName}</td>
            <td>{appointment.doctorName}</td>
            <td>{format(new Date(appointment.dateRdv), 'dd/MM/yyyy hh:mm')}</td>
            <td>{appointment.motifRdv}</td>
            <td>
                <span className="text-nowrap">
                    <span className={appointment.status === "Confirment" ? "text-success" : (appointment.status === STATUSES.rejeted ? "text-danger" : "text-warning")}>
                        {t('statuses.'+appointment.status)}
                    </span>
                </span>
            </td>
        </tr>
    );
};

export default AppointmentListItem;