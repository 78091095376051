/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
import { connect } from 'react-redux';
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { withTranslation } from 'react-i18next';


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };

  hasAccess(menuName) {
      return this.props.currentUser.menus?.filter(menu => menu.code === menuName).length > 0;
  }
  
  canDo(menuCode, actionCode){
    let theMenu = this.props.currentUser.menus?.find(menu => menu.code === menuCode);
    if(!theMenu){
      return false;
    }else{
      return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
    }
  }

  hasMenu(menuCode) {
      return this.props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
  }
  

  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/"); 
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
      "",
      "dashboard",
      "patient-details",
    ]
    
    /// Active menu
    let account = [
      "provider-map",
    ];

    /// Active menu
    let consultations = [
      "consultation-list",
    ];

    /// Active menu
    let rendezVous = [
      "appointments-list",
      "appointment-new",
    ];

    /// Active menu
    let remboursements = [
      "demande-remboursement",
      "remboursement-list",
    ];

    /// Active menu
    let rapports = [
      "rapports/mes-consommations"
    ];

    /// Active menu
    let assures = [
      "assures/liste"
    ];


    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            {this.hasMenu('MENRHVIEWPOLICE') && <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-chart-bar"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "dashboard" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/dashboard"}>
                    <i className="la la-chart-bar"></i>
                    <span className="nav-text">Tableau de bord</span>
                  </Link>
                </li>
              </ul>
            </li>}
            
            {this.props.currentUser.patientId !== 0 && <li className={`${rendezVous.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-calendar-check"></i>
                <span className="nav-text">Rendez-vous</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "appointments-list" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/appointments-list"}>
                    <i className="la la-calendar-check"></i>
                    <span className="nav-text">Mes Rendez-vous</span>
                  </Link>
                </li>
                <li>
                  <Link className={`${path === "appointment-new" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/appointment-new"}>
                    <i className="la la-calendar-check"></i>
                    <span className="nav-text">Nouveau Rendez-vous</span>
                  </Link>
                </li>
              </ul>
            </li>}

            <li className={`${account.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-hospital"></i>
                <span className="nav-text">Prestataires</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "provider-map" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/provider-map"}>
                    <i className="la la-map-marked"></i>
                    <span className="nav-text">Carte des Prestataires</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className={`${account.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-file-invoice-dollar"></i>
                <span className="nav-text">Factures</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "provider-map" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/facturation"}>
                    <i className="la la-file-invoice-dollar"></i>
                    <span className="nav-text">Mes factures</span>
                  </Link>
                </li>
              </ul>
            </li>

            {this.hasMenu('MENRHVIEWPOLICE') && <li className={`${assures.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-file-contract"></i>
                <span className="nav-text">Gestion Polices</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "assures/liste" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/assures/liste"}>
                    <i className="la la-user-shield"></i>
                    <span className="nav-text">Assurés</span>
                  </Link>
                </li>
              </ul>
            </li>}

            {(this.props.currentUser.isAssurePrinc || this.hasMenu('MENRHREMB')) && <li className={`${remboursements.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-cash-register"></i>
                <span className="nav-text">Remboursement</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "demande-remboursement" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/demande-remboursement"}>
                    <i className="la la-cash-register"></i>
                    <span className="nav-text">Demande</span>
                  </Link>
                </li>
                {this.hasMenu('MENRHREMB') &&  <li>
                  <Link className={`${path === "remboursement-list" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/remboursement-list"}>
                    <i className="la la-cash-register"></i>
                    <span className="nav-text">Historique</span>
                  </Link>
                </li>}
              </ul>
            </li>}

            <li className={`${rapports.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i className="la la-chart-pie"></i>
                <span className="nav-text">Rapports</span>
              </Link>
              <ul >
                <li>
                  <Link className={`${path === "rapports/mes-consommations" ? "mm-active" : ""}`} onClick={() => this.props.onClick()} to={"/rapports/mes-consommations"}>
                    <i className="la la-chart-pie"></i>
                    <span className="nav-text">Mes Consommation</span>
                  </Link>
                </li>
              </ul>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      currentUser: currentUser(state),
      authData: state.auth.auth,
  };
};

export default withTranslation()(connect(mapStateToProps)(SideBar));
