import {registerProviderBank, getProvider, searchActes, registerProviderMobileMoney, getAllProviders, getAllProviderDoctors} from '../../services/ProviderService';
import blobFileUpload from '../../services/BlobStorageService';
import { errorToastr, successToastr } from '../../Utils/ToastrMessage';


export const GET_PROVIDER_DATA_CONFIRMED_ACTION = '[getProvider action] confirmed getProvider';
export const GET_PROVIDER_DATA_FAILED_ACTION = '[getProvider action] failed getProvider';

export const START_LOADER_ACTION = '[providerLoader action] started providerLoader';

export const REGISTER_PROVIDER_BANK_FAILED_ACTION = '[createProviderBank action] failed createProviderBank';
export const REGISTER_PROVIDER_BANK_CONFIRMED_ACTION = '[createProviderBank action] confirmed createProviderBank';


export const SEARCH_ACTES_CONFIRMED_ACTION = '[searchActes action] confirmed searchActes';
export const SEARCH_ACTES_FAILED_ACTION = '[searchActes action] failed searchActes';

export const GET_PROVIDER_CONFIRMED_ACTION = '[getProviders action] confirmed getProviders';
export const GET_PROVIDER_FAILED_ACTION = '[getProviders action] failed getProviders';
export const GET_PROVIDER_DOCTORS_CONFIRMED_ACTION = '[getProviderDoctors action] confirmed getProviderDoctors';

var I18n = require('react-redux-i18n').I18n;

export const getProviderDataAction = (id, history) => {
    console.log("Action is called");
    return (dispatch) => {
        getProvider(id)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedGetProviderAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.get_error')+error.response?.data?.message);
            dispatch(getProviderFailedAction(error.response?.data?.message));
            //history.goBack();
        });
    };
};

export const addProviderBank = (bankData, history) => {
    return async (dispatch) => {
        if (bankData.bankDetail.fileSrc) {
            let res = await blobFileUpload([bankData.bankDetail.fileSrc], 'amp-providers-container');
            bankData.bankDetail['ribPhoto'] = res[0];
        }

        /*let datas = bankData.paymentMeans.map(async bdata => {
            bdata['providerId'] = bankData.providerId;
            if(bdata.file) {
                let res = await blobFileUpload([bdata.file], 'amp-providers-container');
                bdata['idPhoto'] = res[0];
            }
            return bdata;
        })*/

        let datas = [];
        for (let i = 0; i<bankData.paymentMeans.length; i++){
            console.log(bankData.paymentMeans[i]);
            let mmdata = bankData.paymentMeans[i];
            mmdata['providerId'] = bankData.providerId;

            if(bankData.paymentMeans[i].file) {
                let res = await blobFileUpload([bankData.paymentMeans[i].file], 'amp-providers-container');
                mmdata['idPhoto'] = res[0];
            }
            datas.push(mmdata);
        }

        console.log(datas);

        let finalData = {
            bankAccount: {providerId: bankData.providerId, ...bankData.bankDetail},
            mobileAccount: datas,
        }

        registerProviderBank(finalData).then((response) => {
            console.log(response);
            dispatch(confirmedRegisterProviderBankAction(response.data));
            history.push('/provider-view/'+bankData.providerId);
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.add_prov_bank_error')+error.response?.data?.message);
            dispatch(registerProviderBankFailedAction(error.response?.data?.message));
        });
    };
};

export const searchActesAction = (keyword) => {
    console.log("Action is called");
    return (dispatch) => {
        searchActes(keyword)
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedSearchActesAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.search_act_error')+error.response?.data?.message);
            dispatch(searchActesFailedAction(error.response?.data?.message));
            //history.goBack();
        });
    };
};


export const listProvidersAction = () => {
    console.log("Action is called");
    return (dispatch) => {
        getAllProviders()
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedProviderListAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.list_providers_error')+error.response?.data?.message);
            dispatch(providerListFailedAction(error.response?.data?.message));
        });
    };
};

export const listProviderDoctorsAction = () => {
    console.log("Action is called");
    return (dispatch) => {
        getAllProviderDoctors()
        .then((response) => {
            console.log(response.data);
            dispatch(confirmedProviderDoctorListAction(response.data));
        })
        .catch((error) => {
            errorToastr(I18n.t('provider.list_provider_error')+error.response?.data?.message);
            dispatch(providerListFailedAction(error.response?.data?.message));
        });
    };
};



export function confirmedGetProviderAction(payload) {
    return {
        type: GET_PROVIDER_DATA_CONFIRMED_ACTION,
        payload,
    };
};

export function getProviderFailedAction(payload) {
    return {
        type: GET_PROVIDER_DATA_FAILED_ACTION,
        payload,
    };
};

export function startLoader() {
    console.log('provider loader is called');
    return {
        type: START_LOADER_ACTION
    };
};


export function confirmedRegisterProviderBankAction(payload) {
    return {
        type: REGISTER_PROVIDER_BANK_CONFIRMED_ACTION,
        payload,
    };
};

export function registerProviderBankFailedAction(payload) {
    return {
        type: REGISTER_PROVIDER_BANK_FAILED_ACTION,
        payload,
    };
};

export function confirmedSearchActesAction(payload) {
    return {
        type: SEARCH_ACTES_CONFIRMED_ACTION,
        payload,
    };
};

export function searchActesFailedAction(payload) {
    return {
        type: SEARCH_ACTES_FAILED_ACTION,
        payload,
    };
};

export function confirmedProviderListAction(payload) {
    return {
        type: GET_PROVIDER_CONFIRMED_ACTION,
        payload,
    };
};

export function confirmedProviderDoctorListAction(payload) {
    return {
        type: GET_PROVIDER_DOCTORS_CONFIRMED_ACTION,
        payload,
    };
};

export function providerListFailedAction(payload) {
    return {
        type: GET_PROVIDER_FAILED_ACTION,
        payload,
    };
};
