import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useParams } from "react-router-dom";
import { Tab, Nav, ListGroup, Badge } from "react-bootstrap";
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import { getClientConsultationsAction } from '../../../../store/actions/ConsultationActions';

const Antecedents = (props) => {
    const { datas } = props;
    const dispatch = useDispatch();

    return (
        <Fragment>
            <div className="row">
                <div className='col-12 h300'>
                    <div className='card'>
                        <div className='card-header border-0 bg-primary'>
                            <h4 className='text-white d-flex align-items-center'>
                                <i className='la la-diagnoses mx-1' style={{fontSize: "25px"}}></i>
                                Allergies et Antecedents
                            </h4>
                        </div>
                        <div className='card-body'>
                            <div className='row my-2'>
                                <div className='col-3'>
                                    <h6 className='text-primary'>Allergies</h6>
                                </div>
                                <div className='col-9 bg-danger rounded p-2'>
                                    {datas.listAllergies?.map((allergy, i) => {
                                        return <Badge variant="dark light mr-1 mb-1" key={i}>
                                            <span>{allergy}</span>
                                        </Badge>
                                        })
                                    }
                                </div>
                            </div>
                            <div className='row my-2'>
                                <div className='col-3'>
                                    <h6 className='text-primary'>Antécédents Familiaux</h6>
                                </div>
                                <div className='col-9 bg-info rounded p-2'>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Poussière</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Arraignées</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Antalgiques</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Polution</span>
                                    </Badge>
                                </div>
                            </div>
                            <div className='row my-2'>
                                <div className='col-3'>
                                    <h6 className='text-primary'>Antécédents Personnels</h6>
                                </div>
                                <div className='col-9 bg-secondary rounded p-2'>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Poussière</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Arraignées</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Antalgiques</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Polution</span>
                                    </Badge>
                                </div>
                            </div>
                            <div className='row my-2'>
                                <div className='col-3'>
                                    <h6 className='text-primary'>Habitudes</h6>
                                </div>
                                <div className='col-9 bg-warning rounded p-2'>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Poussière</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Arraignées</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Antalgiques</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Polution</span>
                                    </Badge>
                                </div>
                            </div>
                            <div className='row my-2'>
                                <div className='col-3'>
                                    <h6 className='text-primary'>Profession</h6>
                                </div>
                                <div className='col-9 bg-success rounded p-2'>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Poussière</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Arraignées</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Antalgiques</span>
                                    </Badge>
                                    <Badge variant="dark light mr-1 mb-1" >
                                        <span>Polution</span>
                                    </Badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(Antecedents);