import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import FacturationListItem from "./FacturationListItem";
import FacturationPagination from './FacturationPagination';
import { getFacturesAction, startLoader } from "../../../../store/actions/FacturationAction";
import { currentUser } from "../../../../store/selectors/CurrentUserSelector";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import {
  Dropdown
} from 'react-bootstrap'

export const FacturationList = (props) => {

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [filterResults, setFilterResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    let max = 10;

    let totalPages = props.factures.length > max ? (props.factures.length % max === 0 ? props.factures.length / max : parseInt(props.factures.length / max) + 1)  : 1;

    const loadPageData = () => {
        let start = (currentPage - 1) * max;
        console.log(start);
        setData(props.factures.slice(start, start+max));
    }

    const changePage = (page) => {
        setCurrentPage(page);
        loadPageData();
    }

    
    useEffect(() => {
        console.log("About to call all the consultations");
        dispatch(startLoader());
        dispatch(getFacturesAction(props.currentUser.id));
        setData();
    }, []);

    const chackbox = document.querySelectorAll(".patient_checkbox input");
    const motherChackBox = document.querySelector(".patient_strg input");
    // console.log(document.querySelectorAll(".all_spending_strg input")[0].checked);
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
                if (motherChackBox.checked) {
                element.checked = true;
                } else {
                element.checked = false;
                }
            } else {
                if (!element.checked) {
                motherChackBox.checked = false;
                break;
                } else {
                motherChackBox.checked = true;
                }
            }
        }
    };

    const filterNoConv = () => {
    }

    return (
        <>
            { props.showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading &&
            <>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                <div className="mr-auto d-none d-lg-block">
                    { props.showLoading && 
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />{' '}
                            <span className="visually-hidden">Un instant...</span>
                        </Button> }
                </div>
                <div className="input-group search-area ml-auto d-inline-flex mr-3">
                    <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    />
                    <div className="input-group-append">
                    <button type="button" className="input-group-text">
                        <i className="flaticon-381-search-2" />
                    </button>
                    </div>
                </div>
                {/*<Link to="#" className="settings-icon">
                    <i className="flaticon-381-settings-2 mr-0" />
                </Link>*/}
                <Dropdown>
                  <Dropdown.Toggle>
                    <i className="flaticon-381-settings-2 mr-0" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='dropdown-menu-right'>
                    <h5 className='dropdown-header'>Filtrer par</h5>
                    <Dropdown.Item onClick={(e) => filterNoConv()}>En cours</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => filterNoConv()}>Non démarré</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => filterNoConv()}>Expirée</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => filterNoConv()}>Terminée</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <div className="table-responsive">
                        <div id="patient_list" className="dataTables_wrapper no-footer">

                            <table
                                id="example5"
                                className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                                role="grid"
                                aria-describedby="example5_info">

                                <thead>
                                    <tr role="row">
                                        <th
                                            className="patient_strg"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-sort="ascending"
                                            style={{ width: 24 }}
                                        >
                                            <div className="checkbox text-right align-self-center">
                                                <div className="custom-control custom-checkbox ">
                                                <input
                                                    type="checkbox"
                                                    onClick={() => chackboxFun("all")}
                                                    className="custom-control-input"
                                                    id="checkAll"
                                                    required
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkAll"
                                                />
                                                </div>
                                            </div>
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient ID: activate to sort column ascending"
                                            style={{ width: 79 }}
                                        >
                                            Id
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Date Check In: activate to sort column ascending"
                                            style={{ width: 113 }}
                                        >
                                            Numéro
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient Name: activate to sort column ascending"
                                            style={{ width: 108 }}
                                        >
                                            Montant Total
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Doctor Assgined: activate to sort column ascending"
                                            style={{ width: 135 }}
                                        >
                                            Date Facturation
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                            style={{ width: 68 }}
                                        >
                                            Statut
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Doctor Assgined: activate to sort column ascending"
                                            style={{ width: 135 }}
                                        >
                                            Date Paiement
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label=": activate to sort column ascending"
                                            style={{ width: 24 }}
                                        >Action</th>

                                    </tr>
                                </thead>

                                <tbody>
                                    {(props.factures?.slice((currentPage - 1) * max, ((currentPage - 1) * max) +max)).map(facture => {
                                        return <FacturationListItem key={facture.id} currentUser={props.currentUser} facture={facture} history={props.history}/>
                                    })}
                                </tbody>

                            </table>
                            <FacturationPagination totalData={props.factures.length} total={totalPages} currentPage={currentPage} switchPage={changePage} max={max} />
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
        </> 
    )
};

const mapStateToProps = (state) => {
    return {
        factures: state.facturation.factures,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(FacturationList);