import axios from 'axios';
const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const consultationServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_CONSULTATION_BACKEND,
  headers: headers
});

const parameterServiceRequest = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_ADMIN_BACKEND,
  headers: headers
});

export const getConsultations = (providerId) => consultationServiceRequest.get('/consultations/bon/'+providerId);
export const getConsultation = (id) => consultationServiceRequest.get('/consultation/'+id);
export const requestConsultation = (data) => consultationServiceRequest.post('/consultation/bon', data);
export const updateConsultation = (data) => consultationServiceRequest.post('/consultation', data);
export const searchDrugs = (keyword) => consultationServiceRequest.get('/medicament/search?keyword='+keyword);
export const searchDiseases = (keyword) => consultationServiceRequest.get('/affection/search?keyword='+keyword);
export const getSuggestion = (type) => consultationServiceRequest.get('/dictionaires/'+type);
export const getClientConsultations = (id) => consultationServiceRequest.get('/consultations/beneficiary/'+id);
export const getClientMedicalFile = (id) => consultationServiceRequest.get('/dossier/beneficiary/'+id);
export const getParameterHistory = (startDate, endDate) => consultationServiceRequest.get('/dossier/healht-parameter/'+startDate+'/'+endDate);
export const getActesConsultation = () => parameterServiceRequest.get('/Parameter/acte/consultation');

//apointment endpoints

export const requestAppointment = (data) => consultationServiceRequest.post('/RendezVous', data);
export const listAppointments = (id) => consultationServiceRequest.get('/RendezVous/patient/'+id);
export const getAppointment = (id) => consultationServiceRequest.get('/RendezVous/'+id);
