
const initialData = {
	patient : {},
    errorMessage: '',
    successMessage: '',
    showLoading: false,
}

export function PatientReducer(state = initialData, action) {
    if (action.type === "IDENTITY_CONFIRMED_ACTION") {
        return {
            ...state,
            patient: action.payload,
            errorMessage: '',
            successMessage: 'Identification Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === "IDENTIFY_FAILED_ACTION") {
        return {
            ...state,
            errorMessage: 'Patient non identifié',
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === "LOADING_TOGGLE_ACTION") {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}

    
