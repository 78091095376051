import axios from 'axios';

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('userDetails'))?.token, 'Accept-Language': localStorage.getItem('currentLanguage')};

const ContratServiceRequest = axios.create({
    baseURL: process.env.REACT_APP_BASEURL_CONTRACT_BACKEND,
    headers: headers 
});


//Contrat
export const getBeneficiaires = (numPolice) => ContratServiceRequest.get('/Contrat/beneficiaire/police/'+numPolice);
export const getAssuresBeneficiaires = (numContrat) => ContratServiceRequest.get('/Contrat/beneficiaire/police/'+numContrat);
export const getSouscripteurs = () => ContratServiceRequest.get('/Contrat/souscripteurs');
export const getGaranties = () => ContratServiceRequest.get('/Contrat/garanties');
export const getActes = () => ContratServiceRequest.get('/Contrat/actes');
export const searchContrat = (data) => ContratServiceRequest.get('/Contrat/search/beneficiaire/contrat/'+data);
export const getBeneficiaire = (id) => ContratServiceRequest.get('/Contrat/Beneficiaire/'+id);
export const getAllSouscripteurs = (page, max, keyword) => ContratServiceRequest.get('/Contrat/all-souscripteurs'+(page ? '?PageNumber='+page : '')+(max ? '&PageSize='+max : '')+(keyword ? '&search='+keyword : ''));
export const getAllAssuresPrincipaux = (page, max, keyword) => ContratServiceRequest.get('/Contrat/assure-principal'+(page ? '?PageNumber='+page : '')+(max ? '&PageSize='+max : '')+(keyword ? '&search='+keyword : ''));
export const getAllBeneficiaires = (page, max, keyword) => ContratServiceRequest.get('/Contrat/all-beneficiaires'+(page ? '?PageNumber='+page : '')+(max ? '&PageSize='+max : '')+(keyword ? '&search='+keyword : ''));
export const getContrats = (page, max, keyword) => ContratServiceRequest.get('/Contrat/all-polices'+(page ? '?PageNumber='+page : '')+(max ? '&PageSize='+max : '')+(keyword ? '&search='+keyword : ''));
export const getAssuresPrincipaux = () => ContratServiceRequest.get('/Contrat/assure-principal/');
export const mapActeProdGarantie = (data) => ContratServiceRequest.post('/Contrat/acte/amp/mapping', data)
//Histoques avenants
export const getHistoSouscripteurs = (page, max, keyword) => ContratServiceRequest.get('/Contrat/souscripteur-histories'+(page ? '?PageNumber='+page : '')+(max ? '&PageSize='+max : '')+(keyword ? '&nom='+keyword : ''));
export const getHistoBeneficiaires = (page, max, keyword) => ContratServiceRequest.get('/Contrat/beneficiaire-histories'+(page ? '?PageNumber='+page : '')+(max ? '&PageSize='+max : '')+(keyword ? '&beneficiaireId='+keyword : ''));
export const getHistoContrats = (page, max, keyword) => ContratServiceRequest.get('/Contrat/police-histories'+(page ? '?PageNumber='+page : '')+(max ? '&PageSize='+max : '')+(keyword ? '&numeroPolice='+keyword : ''));

//Filter data request
export const getContractPerClient = (clientId) => ContratServiceRequest.get('/Contrat/Souscripteurs/'+clientId+'/polices');
export const getCollegePerContract = (contractId) => ContratServiceRequest.get('/Contrat/police/'+contractId+'/colleges');
export const getFamillePerCollege = (collegeId, contractId) => ContratServiceRequest.get('/Contrat/college/'+collegeId+'/'+contractId+'/familles');

//RH requests
export const requestIncorporation = (data) => ContratServiceRequest.post('/RequestAvenant/requests/incorporation', data);
export const requestRetrait = (data) => ContratServiceRequest.post('/RequestAvenant/requests/avenant-retrait', data);
export const requestSuspension = (data) => ContratServiceRequest.post('/Contrat/Souscripteurs/', data);
export const requestAnnulation = (data) => ContratServiceRequest.post('/RequestAvenant/requests/annulation-retrait', data);
export const requestRenouvellement = (data) => ContratServiceRequest.post('/RequestAvenant/requests/renewal', data);
export const requestCollegeSwitch = (data) => ContratServiceRequest.post('/RequestAvenant/requests/avenant-college', data);


export const getRequestIncorporation = (clienId) => ContratServiceRequest.get('/RequestAvenant/requests/incorporation', {params: {codeAssu: clienId}});
export const getRequestRetrait = (clienId) => ContratServiceRequest.get('/RequestAvenant/requests/avenant-retrait', {params: {codeAssu: clienId}});
export const getRequestSuspension = (clienId) => ContratServiceRequest.get('/Contrat/getRequestSuspension/', {params: {codeAssu: clienId}});
export const getRequestAnnulation = (clienId) => ContratServiceRequest.get('/RequestAvenant/requests/cancellation', {params: {codeAssu: clienId}});
export const getRequestRenouvellement = (clienId) => ContratServiceRequest.get('/RequestAvenant/requests/renewal', {params: {codeAssu: clienId}});
export const getRequestCollegeSwitch = (clienId) => ContratServiceRequest.get('/RequestAvenant/requests/college', {params: {codeAssu: clienId}});
